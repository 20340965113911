import React, { useEffect, Fragment, useState } from "react";
import { getAccountDetail } from "../../services/account_services.js";
import UserDetailsSummary from "../../components/account/UserDetailsSummary.js";
import SubscriptionManagement from "../../components/account/SubscriptionManagement.js";
import PageSimple from "../../components/layout/PageSimple.js";

const Account = ({ navOpen, drawerWidth, bannerClearance }) => {
  const [accountDetail, setAccountDetail] = useState({});

  useEffect(() => {
    getAccountDetail()
      .then((accountDetail) => {
        setAccountDetail(accountDetail);
      })
      .catch((error) => {
        setAccountDetail({});
        console.log("Error:", error);
      });
  }, []);

  return (
    <PageSimple navOpen={navOpen} drawerWidth={drawerWidth} bannerClearance={bannerClearance}>
      {accountDetail.whitelistAccess && (
        <Fragment>
          <UserDetailsSummary />
          <SubscriptionManagement accountDetail={accountDetail} />
        </Fragment>
      )}
    </PageSimple>
  );
};

export default Account;
