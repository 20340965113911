import React, { useState, useContext } from 'react';
import ProjectsList from "../../components/projects/ProjectsList.js";
import NewProjectButton from "../../components/projects/NewProjectButton.js";
import ProjectImportButton from "../../components/projects/ProjectImportButton.js";
import StudentFilter from '../../components/common/StudentFilter.js';
import { useTreatments, SplitContext } from "@splitsoftware/splitio-react";
import PageList from '../../components/layout/PageList.js';
import IncludeCompleted from '../../components/projects/IncludeCompleted.js';
import DoubleFabWrapper from '../../components/common/DoubleFabWrapper.js';
import Spinner from '../../components/common/Spinner.js';
import UserContext from '../../UserContext.js';

const Projects = ({ activities, setActivities, students, categories, projects, setProjects, navOpen, drawerWidth, bannerClearance, setNotificationDetails }) => {
    
    const [selectedStudent, setSelectedStudent] = useState(0);
    const [showCompleted, setShowCompleted] = useState(false);
    const [importing, setImporting] = useState(false);
    const { userPermissions } = useContext(UserContext);

    const { isReady } = useContext(SplitContext);
    const treatmentName = {
      projectImport: "project_import"
    };
    const treatment = useTreatments([treatmentName.projectImport]);

    return (
        <PageList navOpen={navOpen} drawerWidth={drawerWidth} bannerClearance={bannerClearance}>
            { userPermissions.filterStudents &&
                <StudentFilter 
                    students={students} 
                    selectedStudent={selectedStudent} 
                    setSelectedStudent={setSelectedStudent} 
                />
            }
            <IncludeCompleted showCompleted={showCompleted} setShowCompleted={setShowCompleted} />
            <ProjectsList 
                activities={activities} 
                setActivities={setActivities} 
                students={students} 
                categories={categories} 
                projects={projects} 
                setProjects={setProjects} 
                selectedStudent={selectedStudent}
                showCompleted={showCompleted}
                setNotificationDetails={setNotificationDetails}
            />
            { userPermissions.addProject &&
                <DoubleFabWrapper>
                    <NewProjectButton 
                        activities={activities} 
                        setActivities={setActivities} 
                        students={students} 
                        categories={categories} 
                        projects={projects} 
                        setProjects={setProjects} 
                    />
                    {/* FEATURE FLAG */}
                    {isReady && treatment[treatmentName.projectImport].treatment === "on" &&
                        <ProjectImportButton
                            activities={activities}
                            setActivities={setActivities}
                            students={students} 
                            categories={categories} 
                            projects={projects} 
                            setProjects={setProjects} 
                            setImporting={setImporting}
                        />
                    }
                </DoubleFabWrapper>
            }
            <Spinner open={importing} />
        </PageList>
    )
}

export default Projects;
