import mixpanel from 'mixpanel-browser';
import { wordCount } from './utils/WordCount.js';
import { DateTime } from "luxon";

let loggingEnabled = true;
const environment = 'production';

if (environment === 'development') {
    loggingEnabled = false;
};

const checkIfEU = (country) => {
    if (typeof country === 'string' && country.length === 2) {
        switch (country) {
            case "AT":
            case "BE":
            case "BG":
            case "HR":
            case "CY":
            case "CZ":
            case "DK":
            case "EE":
            case "FI":
            case "FR":
            case "DE":
            case "GR":
            case "HU":
            case "IE":
            case "IT":
            case "LV":
            case "LT":
            case "LU":
            case "MT":
            case "NL":
            case "PL":
            case "PT":
            case "RO":
            case "SK":
            case "SI":
            case "ES":
            case "SE":
                return true;
            default:
                return false;
        };
    } else {
        console.log("ERROR: Unable to determine country for logging");
        return true;
    };
};

const logIdentify = (data) => {
    const isEU = checkIfEU(data.country);
    if (!isEU && loggingEnabled) {
        try {
            try {
                mixpanel.identify(data.id);
                mixpanel.people.set({
                    "$email": data.email,
                    "Username": data.username,
                    "Date Joined": data.dateJoined,
                    "USER_ID": data.id,
                    "Country Setting": data.country,
                    "Last Profile Update": new Date(),
                });
                if (data.firstName) {
                    mixpanel.people.set({
                        "$first_name": data.firstName,
                    })
                }
                if (data.lastName) {
                    mixpanel.people.set({
                        "$last_name": data.lastName,
                    })
                }
            } catch {
                mixpanel.identify();
                mixpanel.track(
                    "Error",
                    {
                        "Error Details" : "There was a problem identifying this user"
                    }
                );
            };
        } catch {
            console.log("There was an error sending log information");
        };
    };
};

const logAction = (message, country = null, pageValue = null, data = null) => {
    const isEU = checkIfEU(country);
    if (!isEU && loggingEnabled) {
        try {
            if (pageValue) {
                if (data) {
                    data.environment = environment;
                    data.page = pageValue;
                    mixpanel.track(message, data);
                } else {
                    mixpanel.track(message, { 
                        environment: environment,
                        page: pageValue 
                    });
                }
            } else {
                mixpanel.track(message, { 
                    environment: environment
                });
            }
        } catch {
            console.log("There was an error sending log information");
        };
    };
};

const logActivityAction = (message, country = null, pageValue = null, data = null) => {
    const isEU = checkIfEU(country);
    if (!isEU && loggingEnabled) {
        try {
            if (pageValue) {
                if (data) {
                    let leadTime = null;
                    if (data.date) {
                        const dateObj = DateTime.fromISO(data.date).startOf('day');
                        const leadTimeObj = dateObj.diff(DateTime.local().startOf('day'), 'days');
                        leadTime = leadTimeObj.toObject().days;
                    };
                    const activityData = {
                        environment: environment,
                        page: pageValue,
                        _id: data._id,
                        wordCount: wordCount(data.textContent),
                        date: data.date,
                        leadTime: leadTime,
                        dateSentForReview: data.dateSentForReview,
                        dateCompleted: data.dateCompleted,
                        dateCreated: data.dateCreated,
                        students: data.students,
                        categories: data.categories,
                        attachmentCount: data.attachments.length,
                        attachmentLinksCount: data.attachmentLinks.length,
                        project: data.project,
                    };
                    mixpanel.track(message, activityData);
                } else {
                    mixpanel.track(message, { 
                        environment: environment,
                        page: pageValue 
                    });
                }
            } else {
                mixpanel.track(message, { 
                    environment: environment
                });
            }
        } catch {
            console.log("There was an error sending log information");
        };
    };
};

 export { logIdentify, logAction, logActivityAction };
