import React, { useState, useContext, Fragment } from "react";
import { DialogTitle, DialogContent, DialogActions, TextField } from '@material-ui/core';
import { makeStyles } from "@material-ui/core";
import StyledButton from "../common/StyledButton.js";
import UserContext from "../../UserContext.js";
import { updateUserDetails } from '../../services/user_services.js';
import { logAction } from "../../log.js";
import { useLocation } from "react-router-dom";
import { nameIsValid, emailIsValid } from "../../utils/FieldValidation.js";

const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
  }
}));

const UserDetails = ({showModal, handleClose, message = null}) => {
  const classes = useStyles();
  const { firstName, setFirstName, lastName, setLastName, email, setEmail, country } = useContext(UserContext);
  const [firstNameLocal, setFirstNameLocal] = useState(firstName);
  const [lastNameLocal, setLastNameLocal] = useState(lastName);
  const [emailLocal, setEmailLocal] = useState(email);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [firstNameErrorText, setFirstNameErrorText] = useState(false);
  const [lastNameErrorText, setLastNameErrorText] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState(false);
  const location = useLocation();

  const onFirstNameChange = (event) => {
    setFirstNameLocal(event.target.value);
  };

  const onLastNameChange = (event) => {
    setLastNameLocal(event.target.value);
  };

  const onEmailChange = (event) => {
    setEmailLocal(event.target.value);
  };

  const validateUserDetails = () => {
    let isValid = true;
    if (firstNameLocal === "") {
      setFirstNameErrorText("First name is required");
      setFirstNameError(true);
      isValid = false;
    } else if (!nameIsValid(firstNameLocal)) {
      setFirstNameErrorText("First name must be valid");
      setFirstNameError(true);
      isValid = false;
    } else {
      setFirstNameErrorText("");
      setFirstNameError(false);
    };

    if (lastNameLocal === "") {
      setLastNameErrorText("Last name is required");
      setLastNameError(true);
      isValid = false;
    } else if (!nameIsValid(lastNameLocal)) { 
      setLastNameErrorText("Last name must be valid");
      setLastNameError(true);
      isValid = false;
    } else {
      setLastNameErrorText("");
      setLastNameError(false);
    };

    if (emailLocal === "") {
      setEmailErrorText("Email is required");
      setEmailError(true);
      isValid = false;
    } else if (!emailIsValid(emailLocal)) { 
      setEmailErrorText("Email must be valid");
      setEmailError(true);
      isValid = false;
    } else {
      setEmailErrorText("");
      setEmailError(false);
    };

    return isValid;
  };

  const submitUserDetails = (event) => {
    event.preventDefault();
    if (validateUserDetails()) {
      const submitData = {
        firstName: firstNameLocal,
        lastName: lastNameLocal,
        email: emailLocal
      };
      updateUserDetails(submitData).then((response) => {
        logAction("Updated user details", country, location.pathname, submitData); //Mixpanel logging
        setFirstName(firstNameLocal);
        setLastName(lastNameLocal);
        setEmail(emailLocal);
        handleClose();
      }).catch(error => {
          console.log("An error occured during user details submission:", error);
          handleClose();
      });
    };
  };

  return (
    <Fragment>
      {!showModal ? (
        ""
      ) : (
        <Fragment>
          <DialogTitle>
              My Details
          </DialogTitle>
          <DialogContent>
            <form id="form" noValidate onSubmit={submitUserDetails} autoComplete="off">
                {message}
                <TextField
                    className={classes.textField}
                    name="firstName"
                    label="First Name"
                    defaultValue={firstNameLocal}
                    onChange={onFirstNameChange}
                    error={firstNameError}
                    helperText={firstNameErrorText}
                    required
                />
                <TextField
                    className={classes.textField}
                    name="lastName"
                    label="Last Name"
                    defaultValue={lastNameLocal}
                    onChange={onLastNameChange}
                    error={lastNameError}
                    helperText={lastNameErrorText}
                    required
                />
                <TextField
                    className={classes.textField}
                    name="email"
                    label="Email"
                    type="email"
                    defaultValue={emailLocal}
                    onChange={onEmailChange}
                    error={emailError}
                    helperText={emailErrorText}
                    required
                />
            </form>
          </DialogContent>
          <DialogActions>
              <StyledButton form="form" type="submit">OK</StyledButton>
          </DialogActions>
        </Fragment>
      )}
    </Fragment>
  )
}

export default UserDetails;