import React from 'react';
import { Avatar } from '@material-ui/core';
import { Image } from '@material-ui/icons';

const ProjectPlanLogo = (props) => {
    return (
        <Avatar variant="square" alt={`${props.name} logo`} src={props.logoUrl} className={props.className}>
            <Image />
        </Avatar>
    )
}

export default ProjectPlanLogo;