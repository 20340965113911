import React, { Fragment } from 'react';
import { MenuItem, FormControl, Select, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    fullWidth: {
        width: "100%",
    },
}));

const StudentSelector = ({ students, studentsToInclude, setStudentsToInclude, disabled = false, studentsToDisable = [] }) => {

    const classes = useStyles();

    let selectableStudentList = [...students];
    const handleChange = (event) => {
      setStudentsToInclude(event.target.value);
    };

    const listItems = selectableStudentList.map((student) => (
      <MenuItem key={student._id} value={student._id} disabled={studentsToDisable.filter((s) => s === student._id)[0] ? true : false}>
        {student.name}
      </MenuItem>
    ));
    return (
      <Fragment>
        <FormControl className={classes.fullWidth}>
          <InputLabel>Student(s)</InputLabel>
          <Select name={"selectedStudents"} value={studentsToInclude} onChange={handleChange} multiple disabled={disabled}>
            {listItems}
          </Select>
        </FormControl>
      </Fragment>
    );
}

export default StudentSelector;