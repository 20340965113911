import React, {useState, useEffect} from 'react';
import GoalModal from '../../components/goals/GoalModal.js';
import { updateOneGoal, getAllGoals } from '../../services/goals_services.js';

const EditGoal = ({goals, setGoals, data, showGoalModal, setShowGoalModal, }) => {

    const [name, setName] = useState(data.name);
    const [status, setStatus] = useState(data.status);
    const [dateCompleted, setDateCompleted] = useState(data.dateCompleted);

    useEffect(() => {
        if (!showGoalModal){
            setName(data.name);
            setStatus(data.status);
            setDateCompleted(data.dateCompleted);
        }
    }, [showGoalModal, data.name, data.status, data.dateCompleted]);

    const submitFunction = (event) => {
        event.preventDefault();
        const id = data._id;
        const submitData = {
            _id: id,
            name: name,
            status: status,
            dateCompleted: dateCompleted,
        }
        updateOneGoal(submitData).then((response) => {
            getAllGoals()
            .then((response) => {setGoals(response.data)})
            .catch((error) => console.log(error))
            document.getElementById("form").reset()
        }).catch(error =>{
            console.log("An error occured during goal submission:", error);
        });
        setShowGoalModal(false);
    }

    return (
        <GoalModal 
            showGoalModal={showGoalModal} 
            submitFunction={submitFunction} 
            name={name} 
            setName={setName}
            status={status}
            setStatus={setStatus}
            dateCompleted={dateCompleted}
            setDateCompleted={setDateCompleted}
        />
    )
}

export default EditGoal;