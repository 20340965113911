import React from "react";
import saveAs from "file-saver";
import { generatePdfDetails, generateDocumentContent } from "./ActivityReportLib.js";
import { pdf, Page, Text, View, Document, StyleSheet, Font, Image } from "@react-pdf/renderer";
import { DateTime } from "luxon";

Font.register({
  family: "Open Sans",
  fonts: [
    { src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf" },
    { src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf", fontWeight: "bold" },
    { src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-italic.ttf", fontStyle: "italic" },
    { src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700italic.ttf", fontWeight: "bold", fontStyle: "italic" },
  ],
});

const pdfStyles = StyleSheet.create({
  page: {
    margin: 0,
    paddingTop: 50,
    paddingBottom: 60,
    paddingLeft: 50,
    paddingRight: 50,
    fontSize: 12,
    fontFamily: "Open Sans",
  },
  footer: {
    fontSize: 10,
    position: "absolute",
    bottom: 40,
    right: 50,
  },
  title: {
    fontSize: 26,
    textAlign: "center",
    marginBottom: 20,
  },
  group: {
    marginTop: 10,
  },
  groupHeader: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 15,
  },
  activity: {
    marginBottom: 10,
  },
  bold: {
    fontWeight: "bold",
  },
  imageContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "flex-start"
  },
  image: {
    width: "50%",
    objectFit: "contain",
    padding: 2,
  }
});

const activityReport = async (activitiesByDate, students, categories, projects, exportFileName, includeImages, country, setDownloading) => {
  var documentContent = await generateDocumentContent(activitiesByDate, students, categories, projects, includeImages);

  const blob = await pdf(
    <Document>
      <Page size={country === "US" ? "LETTER" : "A4"} style={pdfStyles.page}>
        <Text style={pdfStyles.footer} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} fixed />
        <Text style={pdfStyles.title}>{exportFileName}</Text>
        {documentContent.map((group, i) => {
          return (
            <View key={i} style={pdfStyles.group}>
              <Text style={pdfStyles.groupHeader}>{group.hasOwnProperty("groupDate") ? group.groupDate : ""}</Text>
              {group.hasOwnProperty("groupData") &&
                group.groupData.map((activity, j) => {
                  const locale = country === "US" ? "en-us" : "en-au";
                  function returnDate() {
                    if (activity.date) {
                      const date = DateTime.fromISO(activity.date);
                      const formattedDate = date.toLocaleString({
                        month: "short",
                        day: "2-digit",
                        locale: locale,
                      });
                      return (
                        <Text>
                          <Text style={pdfStyles.bold}>Date:</Text> {formattedDate}
                        </Text>
                      );
                    }
                  }

                  function returnDateCompleted() {
                    if (activity.dateCompleted) {
                      const dateCompleted = DateTime.fromISO(activity.dateCompleted);
                      const formattedDateCompleted = dateCompleted.toLocaleString({
                        month: "short",
                        day: "2-digit",
                        locale: locale,
                      });
                      return (
                        <Text>
                          <Text style={pdfStyles.bold}>Date Completed:</Text> {formattedDateCompleted}
                        </Text>
                      );
                    }
                  }
                  return (
                    <View key={j} style={pdfStyles.activity}>
                      {returnDate()}
                      {returnDateCompleted()}
                      <Text>
                        <Text style={pdfStyles.bold}>Student(s):</Text> {activity.students.toString()}
                      </Text>
                      <Text>
                        <Text style={pdfStyles.bold}>Category(s):</Text> {activity.categories.toString()}
                      </Text>
                      {activity.project &&
                        <Text>
                          <Text style={pdfStyles.bold}>Project:</Text> {activity.project}
                        </Text>
                      }
                      <Text style={pdfStyles.bold}>Details:</Text>
                      <View style={pdfStyles.details}>{activity.details.map(generatePdfDetails)}</View>
                      {includeImages && activity.images.length > 0 && <Text style={pdfStyles.bold}>Attached Image(s):</Text>}
                      {includeImages &&
                        activity.images.length > 0 &&
                        <View style={pdfStyles.imageContainer}>
                          {activity.images.map((image, i) => {
                            if (image.signedUrl !== "") {
                              return <Image key={i} src={image.signedUrl} style={pdfStyles.image} />;
                            } else {
                              return (
                                <Text key={i} style={pdfStyles.details}>
                                  Image failed to load.
                                </Text>
                              );
                            }
                          })}
                        </View>
                      }
                    </View>
                  );
                })}
            </View>
          );
        })}
      </Page>
    </Document>
  ).toBlob();

  setDownloading(false);
  saveAs(blob, exportFileName);
};

export default activityReport;
