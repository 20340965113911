import { getAttachmentLink } from "../../services/attachment_services.js";

const getThumbnails = async (attachments, setThumbnails) => {
  const promises = attachments.map(async (attachment) => {
    const file = await getAttachmentLink({ fileName: attachment.s3FileName })
      .then((response) => {
        const fileObj = {
          userFileName: attachment.userFileName,
          s3FileName: attachment.s3FileName,
          signedUrl: response.data.data.signedUrl,
          fileType: attachment.fileType,
        };
        return fileObj;
      })
      .catch((error) => {
        console.log(error);
      });
    return file;
  });
  const files = await Promise.all(promises);
  setThumbnails(files);
}

async function getImageObj(attachment, response) {
    const signedUrl = response.data.data.signedUrl;
    const fileObj = {
      userFileName: attachment.userFileName,
      s3FileName: attachment.s3FileName,
      signedUrl: signedUrl,
      fileType: attachment.fileType,
    };
    return fileObj;
}

async function getImages(attachments) {
  const images = attachments.filter((attachment) => {
    switch (attachment.fileType) {
      case "image/jpeg":
      case "image/png":
        return attachment;
      default:
        return false;
    };
  });
  const promises = images.map(async (image) => {
      const response = await getAttachmentLink({ fileName: image.s3FileName });
      const file = await getImageObj(image, response);
      return file;
  });
  return await Promise.all(promises);
}

export { getThumbnails, getImages };
