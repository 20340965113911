import React from "react";
import ReactDOM from "react-dom";
import "typeface-roboto";
import App from "./App.js";
import { MuiThemeProvider } from "@material-ui/core";
import { CssBaseline } from "@material-ui/core";
import theme from "./theme.js";
import "./assets/css/react_sortable_tree_overrides.css";

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <App />
  </MuiThemeProvider>,
  document.getElementById("root")
);
