import React, { useState, useContext, useRef, useEffect } from "react";
import clsx from 'clsx';
import { makeStyles } from "@material-ui/core";
import { TextField, Avatar, Typography, IconButton, Menu, MenuItem, alpha } from "@material-ui/core";
import UserContext from "../../UserContext.js";
import { DateTime } from "luxon";
import { MoreVert } from '@material-ui/icons';
import StyledButton from "../common/StyledButton.js";
import { logAction } from "../../log.js";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginBottom: theme.spacing(1),
    alignItems: "flex-start",
  },
  rootRightAligned: {
    flexDirection: "row-reverse"
  },
  commentWrapper: {
    display: "flex",
    flexDirection: "column",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  commentWrapperRightAligned: {
    alignItems: "flex-end"
  },
  commentBubble: {
    minWidth: 0,
    width: "100%",
    marginBottom: theme.spacing(0.5)
  },
  commentBubbleInput: {
    boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
    borderRadius: 4,
    fontSize: "0.9rem",
  },
  commentBubbleInputEditMode: {
    boxShadow: "none",
    borderRadius: 0,
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: theme.palette.grey[400],
  },
  commentBubbleTextarea: {
    color: theme.palette.text.primary,
    padding: theme.spacing(1)
  },
  avatar: {
    height: theme.spacing(4),
    width: theme.spacing(4),
  },
  iconButton: {
    color: theme.palette.grey[500]
  },
  pseudoTextField: {
    whiteSpace: 'pre-wrap', 
    wordWrap: 'break-word',
    fontSize: "0.9rem",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    visibility: "hidden",
    height: 0
  },
  multiline: {
    padding: 0
  },
  editButton: {
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5)
  }
}));

const Comment = ({comments, setComments, comment, students, index, handleDelete, location}) => {
    const classes = useStyles();
    const { country, primaryFirstName, userId, userPermissions } = useContext(UserContext);
    const locale = country === "US" ? "en-us" : "en-au";
    const [anchorEl, setAnchorEl] = useState(null);
    const [commentText, setCommentText] = useState("");
    const [editMode, setEditMode] = useState(false);
    const [userInitial, setUserInitial] = useState(null);
    const [userColor, setUserColor] = useState("#477DD8"); //This is the same as theme.palette.info.main
    const inputRef = useRef(null);

    let formattedDate = DateTime.fromISO(comment.dateCreated).toLocaleString({day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: '2-digit', locale: locale});

    useEffect(() => { //This is to ensure that the TextField is given focus after the menu is closed
      if (editMode) {
        inputRef.current?.focus();
        inputRef.current.selectionStart = inputRef.current.value.length;
      }
    }, [anchorEl, editMode]);

    useEffect(() => {
      setCommentText(comment.text)
      if (comment.studentId) {
        const studentRecord = students.find((student) => student._id === comment.studentId);
        if (studentRecord) {
          setUserInitial(studentRecord.name.substring(0, 1).toUpperCase());
          setUserColor(studentRecord.color)
        };
      } else {
        if (primaryFirstName) {
          setUserInitial(primaryFirstName.substring(0, 1).toUpperCase());
        }
      }
    }, [comments, setComments, comment, students]);

    const commentMenuOpen = (event) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    };
  
    const commentMenuClose = (event) => {
      event.stopPropagation();
      setAnchorEl(null);
    };

    const onCommentTextChange = (e) => {
      setCommentText(e.target.value)
    }
  
    const onCancel = () => {
      setCommentText(comments[index].text);
      setEditMode(false);
    }

    const onSave = () => {
      logAction("Updated activity comment", country, location.pathname); //Mixpanel logging
      let commentsTemp = comments;
      commentsTemp[index].text = commentText;
      setComments([...commentsTemp]);
      setEditMode(false);
    }

    const editComment = () => {
      setAnchorEl(null);
      setEditMode(true);
    }

    const deleteComment = () => {
      setAnchorEl(null);
      handleDelete(index);
    }
  
    const commentMenu = () => {
      return (
        <Menu 
          id="comment-menu" 
          anchorEl={anchorEl} 
          keepMounted 
          open={Boolean(anchorEl)} 
          onClick={commentMenuClose} 
          onClose={commentMenuClose}
          getContentAnchorEl={null}
        >
          <MenuItem dense onClick={editComment}>Edit</MenuItem>
          <MenuItem dense onClick={deleteComment}>Delete</MenuItem>
        </Menu>
      )
    }

    const hasPermission = () => {
      let returnValue = false;
      if (userPermissions.activity.editAnyComment) {
        returnValue = true;
      } else {
        if (userPermissions.activity.editOwnComment) {
          if (userId === comment.userId) {
            returnValue = true;
          }
        }
      }
      return returnValue;
    }

    return (
      <div className = {clsx(classes.root, {[classes.rootRightAligned]: comment.studentId})}>
        <Avatar className={classes.avatar} style={{backgroundColor: userColor}}>{userInitial}</Avatar>
        <div className = {clsx(classes.commentWrapper, {[classes.commentWrapperRightAligned]: comment.studentId})}>
          <div className={classes.pseudoTextField}> {/* This is to stretch the div to the right width for the text. This is neccessary because TextField renders as a textarea which doesnt support dynamic width */}
            {commentText}
          </div>
          <TextField //This is highly customised to make it look like a comment bubble until its edited
            inputRef={inputRef}
            className={classes.commentBubble}
            value={commentText} 
            onChange={onCommentTextChange}
            multiline
            disabled={!editMode}
            InputProps={{
              style: {backgroundColor: !editMode && alpha(userColor, 0.4)},
              disableUnderline: true,
              className: clsx(classes.commentBubbleInput, {[classes.commentBubbleInputEditMode]: editMode}),                
              classes: {multiline: classes.multiline}
            }}
            inputProps={{
              cols: 1,
              className: classes.commentBubbleTextarea
            }}
          />
          {
            editMode
            ? <div>
              <StyledButton size="small" className={classes.editButton} onClick={onSave}>
                Save
              </StyledButton>
              <StyledButton size="small" className={classes.editButton} onClick={onCancel}>
                Cancel
              </StyledButton>
            </div>
            : null
          }
          <Typography variant="caption">{formattedDate}</Typography>
        </div>
        { hasPermission() && 
          <IconButton size="small" className={classes.iconButton} onClick={commentMenuOpen}>
            <MoreVert />
          </IconButton>
        }
        {commentMenu()}
      </div>
    )
};

export default Comment;