import React, { Fragment, useContext } from "react";
import { useGlobalState } from "../../config/store.js";
import UserContext from "../../UserContext.js";
import { useLocation } from "react-router-dom";
import { setLoggedInUser } from "../../services/auth_services.js";
import { logAction } from "../../log.js";

const Logout = ({trigger}) => {
    const { dispatch } = useGlobalState();
    const { country, setCountry } = useContext(UserContext);
    const location = useLocation();

    if (trigger) {
        logAction("Logged out", country, location.pathname); //Mixpanel logging
        setCountry("");
        dispatch({ type: "setLoggedInUser", data: null });
        setLoggedInUser(null);
        window.location.href = "/API/auth/logout";
    }

    return <Fragment />;
};

export default Logout;