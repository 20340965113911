import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { parseFragment } from "parse5";
import { getImages } from "./GetAttachments.js";

const pdfStyles = StyleSheet.create({
  paragraph: {
    marginBottom: 10,
  },
  unorderedList: {
    marginBottom: 10,
    paddingLeft: 15,
  },
  orderedList: {
    marginBottom: 10,
    paddingLeft: 15,
  },
  bold: {
    fontWeight: "bold",
  },
  italic: {
    fontStyle: "italic",
  },
  underline: {
    textDecoration: "underline",
  }
});

function traverseElementTree(element, elementObject) {
  if ("childNodes" in element) {
    for (let childElement of element.childNodes) {
      let childElementObject = {
        content: [],
        parent: childElement.parentNode.nodeName,
      };
      switch (childElement.nodeName) {
        case "#text":
          childElementObject.style = "string";
          break;
        case "strong":
          childElementObject.style = "bold";
          break;
        case "em":
          childElementObject.style = "italic";
          break;
        case "ul":
          childElementObject.style = "unorderedList";
          break;
        case "ol":
          childElementObject.style = "orderedList";
          break;
        case "li":
          childElementObject.style = "listItem";
          break;
        case "span":
          childElementObject.style = "span";
          if (childElement.attrs.length) {
            childElementObject.style = "styledSpan";
            childElementObject.highlightColor = null;
            childElementObject.underline = null;
            if (childElement.attrs[0].value.includes("text-decoration: underline;")) {
              childElementObject.underline = true;
            }
            if (childElement.attrs[0].value.includes("background-color")) {
              const highlightColor = childElement.attrs[0].value.match(/#(.{3,6})/g);
              if (Array.isArray(highlightColor)) {
                childElementObject.highlightColor = highlightColor[0];
              };
            }
          }
          break;
        default:
          break;
      }
      //console.log(childElement);
      elementObject.content.push(childElementObject);
      traverseElementTree(childElement, childElementObject);
    }
  } else {
    //console.log(element);
    elementObject.content.push(element.value);
  }
  //console.log(elementObject);
  return elementObject;
}

function generatePdfDetails(element, i) {
  //console.log(element);
  let styleClass = {};
  switch (element.style) {
    case "paragraph":
      styleClass = pdfStyles.paragraph;
      break;
    case "unorderedList":
      styleClass = pdfStyles.unorderedList;
      break;
    case "orderedList":
      styleClass = pdfStyles.orderedList;
      break;
    case "bold":
      styleClass = pdfStyles.bold;
      break;
    case "italic":
      styleClass = pdfStyles.italic;
      break;
    case "listItem":
      styleClass = pdfStyles.listItem;
      break;
    case "styledSpan":
      let styleArray = [];
      if (element.underline) {
        styleArray.push(pdfStyles.underline)
      }
      if (element.highlightColor) {
        styleArray.push({backgroundColor: element.highlightColor})
      }
      styleClass = styleArray;
      break;
    default:
      break;
  }
  switch (element.style) {
    case "paragraph":
    case "bold":
    case "italic":
    case "span":
    case "styledSpan":
      return (
        <Text key={i} style={styleClass}>
          {element.content.map(generatePdfDetails)}
        </Text>
      );
    case "listItem":
      return element.content.map((child, index) => {
        if (child.style === "string") {
          return (
            <Text key={index} style={styleClass}>
              &bull; {child.content[0]}
            </Text>
          );
        } else if (child.style === "unorderedList") {
          //the child is another list
          return generatePdfDetails(child, index);
        } else {
          return (
            <Text key={index} style={styleClass}>
              &bull; {generatePdfDetails(child, index)}
            </Text>
          );
        }
      });
    case "unorderedList":
    case "orderedList":
      return (
        <View key={i} style={styleClass}>
          {element.content.map(generatePdfDetails)}
        </View>
      );
    case "string":
      return element.content[0];
    default:
      break;
  }
}

const generateDocumentContent = async (activitiesByDate, students, categories, projects, includeImages) => {
  const documentContent = [];
  for (const group of activitiesByDate) {
    const arrayLength = documentContent.push({
      groupDate: group.dateLabel,
      groupData: [],
    });
    const arrayPosition = arrayLength - 1;
    for (const activity of group.activities) {
      let project = null;
      if (activity.project) {
        const projectRecord = projects.find((project) => project._id === activity.project)
        if (projectRecord) {
          project = projectRecord.name
        }
      }
      const activityText = activity.textContent.replace(/[\n\r]/g, "");
      const htmlObject = parseFragment(activityText);
      const activityTextStructured = [];
      for (let [nodeKey, node] of Object.entries(htmlObject)) {
        if (nodeKey === "childNodes") {
          for (let elementArray of Object.entries(node)) {
            let element = elementArray[1];
            let elementObject = { content: [] };
            switch (element.nodeName) {
              case "p":
                elementObject.style = "paragraph";
                break;
              case "ul":
                elementObject.style = "unorderedList";
                break;
              case "ol":
                elementObject.style = "orderedList";
                break;
              default:
                break;
            }
            const elementStructured = traverseElementTree(element, elementObject);
            activityTextStructured.push(elementStructured);
          }
        }
      }
      var activityImages = [];
      if (includeImages && activity.attachments.length > 0) {
        activityImages = await getImages(activity.attachments);
      }

      documentContent[arrayPosition].groupData.push({
        date: activity.date,
        dateCompleted: activity.dateCompleted,
        students: activity.students.map((student_id) => {
          let studentRecord = students.find((student) => student._id === student_id);
          if (studentRecord) {
            return studentRecord.name;
          } else {
            return null;
          }
        }),
        categories: activity.categories.map((category_id) => {
          let categoryRecord = categories.find((category) => category._id === category_id);
          if (categoryRecord) {
            return categoryRecord.name;
          } else {
            return null;
          }
        }),
        project: project,
        details: activityTextStructured,
        images: activityImages,
      });
    };
  };
  return documentContent;
};

export { generatePdfDetails, generateDocumentContent };
