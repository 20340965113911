import { updateProjectsFromProjectPlans } from "../services/project_services.js";

const updateProjects = (projectUpdatesRequired, setUpdatingProjects, setCheckedForProjectUpdates, setProjectUpdateResults, setShowModalProjectUpdateResults) => {
    setUpdatingProjects(true);
    setCheckedForProjectUpdates(true);
    updateProjectsFromProjectPlans(projectUpdatesRequired)
    .then((response) => {
      setUpdatingProjects(false);
      setCheckedForProjectUpdates(true);
      setProjectUpdateResults(response.data.results);
      setShowModalProjectUpdateResults(true);
    })
};

const checkForProjectUpdates = (projects, setProjectUpdatesRequired, setShowModalProjectUpdateConfirm, setUpdatingProjects, setCheckedForProjectUpdates, setProjectUpdateResults, setShowModalProjectUpdateResults, debugMode) => {
    const projectUpdatesRequiredTemp = {
      major: [],
      minor: [],
      patch: []
    }
    projects.map((project) => { //Check if there are any projects that require an update
      if (project.majorUpdateRequired) {
        projectUpdatesRequiredTemp.major.push({
          _id: project._id,
          name: project.name,
          projectPlan: project.projectPlan
        });
        return true;
      } else if (project.minorUpdateRequired) {
        projectUpdatesRequiredTemp.minor.push({
          _id: project._id,
          name: project.name,
          projectPlan: project.projectPlan
        });
        return true;
      } else if (project.patchUpdateRequired) {
        projectUpdatesRequiredTemp.patch.push({
          _id: project._id,
          name: project.name,
          projectPlan: project.projectPlan
        });
        return true;
      } else {
        return true;
      }
    })
    setProjectUpdatesRequired(projectUpdatesRequiredTemp);
    const totalUpdateCount = projectUpdatesRequiredTemp.major.length + projectUpdatesRequiredTemp.minor.length + projectUpdatesRequiredTemp.patch.length;
    if (totalUpdateCount) {
      if (debugMode) { //User has debugMode flag enabled
        setShowModalProjectUpdateConfirm(true);
      } else {
        updateProjects(projectUpdatesRequiredTemp, setUpdatingProjects, setCheckedForProjectUpdates, setProjectUpdateResults, setShowModalProjectUpdateResults);
      }
    };
};

export { updateProjects, checkForProjectUpdates };