import api from "../config/api.js";

export const getAllGoals = async () => {
  try {
    return await api.get("goals/getGoalsByUser");
  } catch (error) {
    if (typeof error.response !== "undefined") {
      if (error.response.status === 401) {
        window.location.href = "/API/auth/login";
      }
    }
    console.log(error);
    throw error;
  }
};

export const updateGoals = async (data) => {
  try {
    return await api.post("goals/updateGoals", data);
  } catch (error) {
    if (typeof error.response !== "undefined") {
      if (error.response.status === 401) {
        window.location.href = "/API/auth/login";
      }
    }
    console.log(error);
    throw error;
  }
};

export const updateOneGoal = async (data) => {
  try {
    return await api.post("goals/updateOneGoal", data);
  } catch (error) {
    if (typeof error.response !== "undefined") {
      if (error.response.status === 401) {
        window.location.href = "/API/auth/login";
      }
    }
    console.log(error);
    throw error;
  }
};
