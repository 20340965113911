import React, { Fragment, useState, useEffect } from "react";
import { IconButton, Drawer, List, ListItem, ListItemText, ListItemAvatar, Collapse, Avatar, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { alpha } from '@material-ui/core';
import CustomProgressBar from '../common/CustomProgressBar.js'
import { Close } from '@material-ui/icons';
import { FlagOutlined } from '@material-ui/icons';
import { ExpandLess } from '@material-ui/icons';
import { ExpandMore } from '@material-ui/icons';
import FlaggedGoal from './FlaggedGoal.js'
import { getAllGoals } from '../../services/goals_services.js';
import { useCallback } from "react";

const useStyles = makeStyles(theme => ({
    toolbar: theme.mixins.toolbar,
    drawerHeader: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    list: {
        width: "400px",
        [theme.breakpoints.down('xs')]: {
            width: "100vw"
        },
    },
    studentHeader: {
        paddingRight: theme.spacing(2),
    },
    nameProgressContainer: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        paddingRight: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            flexDirection: "column"
        },
    },
    studentName: {
        width: "60%",
        [theme.breakpoints.down('xs')]: {
            width: "100%",
        },
    },
    root: {
        backgroundColor: "red",
    }
}));

const FlaggedGoals = ({students, goals, setGoals}) => {
    const classes = useStyles();
    const [goalsOpen, setGoalsOpen] = useState(false);
    const [flaggedGoals, setFlaggedGoals] = useState([]);

    const handleClick = (index) => {
        setFlaggedGoals(flaggedGoals.map((student, i) => {
            if (i === index) {
                return {
                    name: student.name,
                    color: student.color,
                    expanded: student.expanded ? false : true,
                    goals: student.goals,
                }
            } else {
                return student
            }
        }))
      };

    function toggleGoals() {
        goalsOpen ? setGoalsOpen(false) : setGoalsOpen(true);
    }

    const fullrefresh = useCallback(() => {
        setFlaggedGoals(students.map((student, i) => {
            return {
                name: student.name,
                color: student.color,
                expanded: false,
                goals: goals.filter((goal) => student._id === goal.student && goal.flag)
            }
        }));
    }, [students, goals])

    useEffect(() => {
        getAllGoals()
        .then((response) => {setGoals(response.data)})
        .catch((error) => console.log(error))
    }, [goalsOpen, setGoals]);

    useEffect(() => {
        fullrefresh();
    }, [students, fullrefresh]);

    useEffect(() => {
        if (flaggedGoals.length) { //If flaggedGoals already exists, leave expanded prop unchanged
            setFlaggedGoals(flaggedGoals.map((flaggedGoalObj) => {
                let newFlaggedGoalObj = students.filter((student) => student.name === flaggedGoalObj.name).map((student) => {
                    return {
                        name: student.name,
                        color: student.color,
                        goals: goals.filter((goal) => student._id === goal.student && goal.flag)
                    }
                })
                newFlaggedGoalObj = newFlaggedGoalObj[0];
                newFlaggedGoalObj.expanded = flaggedGoalObj.expanded;
                return newFlaggedGoalObj;
            }));
        } else {
            fullrefresh();
        }
    }, [goals, fullrefresh, flaggedGoals, students]);

    return (
        <Fragment>
            <IconButton edge="end" color="inherit" onClick={() => toggleGoals()}>
                <FlagOutlined />
            </IconButton>
            <Drawer 
                classes={{ paper: classes.paper }}
                variant={"temporary"}
                open={goalsOpen}
                onClose={() => {setGoalsOpen(false)}}
                anchor={"right"}
            >
                <Toolbar disableGutters={true}>
                    <div className={classes.drawerHeader}>
                        <IconButton onClick={() => {setGoalsOpen(false)}}>
                            <Close />
                        </IconButton>
                    </div>
                </Toolbar>
                <List
                    className={classes.list}
                >
                    {flaggedGoals.map((student, i) => {
                        const studentInitial = student.name.substring(0, 1).toUpperCase();
                        const secondaryColor = alpha(student.color, 0.4);
                        const percentComplete = (student.goals.filter((goal) => {return goal.status === "Complete"}).length / student.goals.length) * 100
                        return (
                            <Fragment key={i}>
                                <ListItem button onClick={() => {handleClick(i)}} className={classes.studentHeader}>
                                    <ListItemAvatar>
                                        <Avatar style={{ backgroundColor: `${student.color}` }}>{studentInitial}</Avatar>
                                    </ListItemAvatar>
                                    <div className={classes.nameProgressContainer}>
                                        <ListItemText className={classes.studentName} primary={student.name} primaryTypographyProps={{noWrap: true}}/>
                                        <CustomProgressBar 
                                            color={student.color}
                                            secondaryColor={secondaryColor}
                                            value={percentComplete} 
                                        />
                                    </div>
                                    {student.expanded ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>
                                <Collapse in={student.expanded} timeout="auto" unmountOnExit>
                                    {student.goals.length > 0 && student.goals.map((goal, g) => {
                                        return (
                                            <List key={g} disablePadding>
                                                <FlaggedGoal 
                                                    goal={goal}
                                                    goals={goals}
                                                    setGoals={setGoals}
                                                />
                                            </List>
                                        )
                                    })}
                                </Collapse>
                            </Fragment>
                        )
                    })}
                </List>
            </Drawer>
        </Fragment>
    )
}

export default FlaggedGoals;