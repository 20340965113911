import React, { useState, useEffect, Fragment, useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import { Dialog, DialogTitle, DialogContent, DialogActions, useTheme, useMediaQuery, IconButton, Menu, MenuItem, Typography, Button, Badge, Tooltip } from '@material-ui/core';
import { Alert } from "@material-ui/lab";
import { deleteProject } from '../../services/project_services.js';
import { ArrowBack } from "@material-ui/icons";
import { MoreVert } from "@material-ui/icons";
import { Done } from "@material-ui/icons";
import { AttachFile } from '@material-ui/icons';
import { Add } from '@material-ui/icons';
import ActivitiesList from '../activities/ActivitiesList.js';
import NewActivity from '../../containers/activities/NewActivity.js';
import StatusTabs from '../activities/StatusTabs.js';
import ProjectEditModal from './ProjectEditModal.js';
import ProjectDuplicateModal from './ProjectDuplicateModal.js';
import ProjectAttachmentsModal from './ProjectAttachmentsModal.js';
import { getFilteredData } from '../../containers/activities/FilterData.js';
import { getAllActivities } from '../../services/activity_services.js';
import StyledButton from '../common/StyledButton.js';
import Confetti from "../common/Confetti.js";
import {updateProject} from '../../services/project_services.js';
import { logAction } from "../../log.js";
import UserContext from "../../UserContext.js";
import { useLocation } from "react-router-dom";
import { deleteMultipleAttachments } from "../../services/attachment_services.js";
import { DateTime } from "luxon";
import { useMemo } from 'react';
import { useTreatments, SplitContext } from "@splitsoftware/splitio-react";

const useStyles = makeStyles(theme => ({
    dialogContentRoot: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    dialogTitle: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        alignItems: "center",
    },
    titleText: {
        flexGrow: 1,
    },
    attachmentCountZero: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.grey[300],
    },
    completedButton: {
        backgroundColor: theme.palette.complete.main,
        borderColor: theme.palette.complete.main,
        color: theme.palette.common.white,
        '&:hover': {
          backgroundColor: theme.palette.complete.shade[700],
          borderColor: theme.palette.complete.shade[700],
        },
    },
}));

const ProjectModal = ({ activities, setActivities, students, categories, projects, setProjects, isOpen, setShowProjectModal, data, name, setName, color, setColor, icon, setIcon, description, setDescription, attachments, setAttachments, projectId, setProjectId, dateCompleted, setDateCompleted, studentsToInclude, setStudentsToInclude, categoriesToInclude, setCategoriesToInclude, setNotificationDetails }) => {

    const classes = useStyles();
    const theme = useTheme();
    const screenIsXs = useMediaQuery(theme.breakpoints.down("xs"));
    const [activeTab, setActiveTab] = useState(0);
    const [sortMethod, setSortMethod] = useState("date");
    const [filterIndex, setFilterIndex] = useState(0);
    const [filterCounts, setFilterCounts] = useState([]);
    const [filterCountTotal, setFilterCountTotal] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showCompleteConfirm, setShowCompleteConfirm] = useState(false);
    const [showEditProjectModal, setShowEditProjectModal] = useState(false);
    const [showDuplicateProjectModal, setShowDuplicateProjectModal] = useState(false);
    const [showProjectAttachmentsModal, setShowProjectAttachmentsModal] = useState(false);
    const [showNewActivityModal, setShowActivityModal] = useState(false);
    const [fire, setFire] = useState(false);
    const [clickCoords, setClickCoords] = useState([]);
    const { country, userPermissions } = useContext(UserContext);
    const maxActivitiesForDuplication = 99;
    const { isReady } = useContext(SplitContext);
    const treatmentName = {
      studentLogin: "student_login"
    };
    const treatment = useTreatments([treatmentName.studentLogin]);

    const location = useLocation();

    const filters = useMemo(() => {
        const incompleteActivities = getFilteredData(activities, {
            student: 0,
            completed: 0, 
            date: {
                mode: 3
            },
            project: projectId,
        })
        const completedActivities = getFilteredData(activities, {
            student: 0,
            completed: 1, 
            date: {
                mode: 2
            },
            project: projectId,
        })
        setFilterCounts([[incompleteActivities.length], [completedActivities.length]])
        setFilterCountTotal(incompleteActivities.length + completedActivities.length)
        return [incompleteActivities, completedActivities]
    }, [activities, projectId])


    useEffect(() => {
        getAllActivities()
            .then((response) => setActivities(response.data))
            .catch((error) => console.log(error))
    }, [setActivities]);

    useEffect(() => {
        switch (activeTab) {
            case 0:
                setFilterIndex(0)
                setSortMethod("date")
                break
            case 1:
                setFilterIndex(1)
                setSortMethod("dateCompleted")
                break
            default: 
                break
        }
    }, [activeTab]);

    const projectMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
      };
    
    const projectMenuClose = () => {
        setAnchorEl(null);
    };

    const removeProject = () => {
        deleteProject({ deleteId: projectId })
        .then(() => {
            logAction("Deleted project", country, location.pathname, data); //Mixpanel logging
            setProjects(projects.filter((project) => project._id !== projectId));
            logAction("Deleting project attachments", country, location.pathname, data); //Mixpanel logging
            if (attachments.length) { //Delete project attachments
                deleteMultipleAttachments(attachments.map((attachment) => {return attachment.s3FileName}))
                .catch((error) => {
                    console.log("Error:", error);
                });
            };
            const incompleteActivities = filters[0]; //Delete activity attachments
            const allActivityAttachments = [];
            incompleteActivities.map((activity) => {
                if (activity.attachments.length) {
                    activity.attachments.map((attachment) => {
                        allActivityAttachments.push(attachment);
                        return null;
                    });
                };
                return null;
            });
            if (allActivityAttachments.length) {
                deleteMultipleAttachments(allActivityAttachments.map((attachment) => {return attachment.s3FileName}))
                .catch((error) => {
                console.log("Error:", error);
                });
            };
        })
        .catch((error) => {
          console.log("Error:", error);
        });
    };

    const onDelete = () => {
        setShowDeleteConfirm(false);
        setShowProjectModal(false);
        removeProject();
    }; 

    const onClose = () => {
        if (dateCompleted === data.dateCompleted) {
            setShowProjectModal(false);
        } else {
            submitUpdatedProject();
            setShowProjectModal(false);
        };
    }; 

    const completeButton = () => {
        const incompleteActivityCount = filters[0].length;
        const completeConfirmModal = () => {
            return (
              <Dialog open={showCompleteConfirm}>
                <DialogTitle>Project Completion</DialogTitle>
                <DialogContent>
                    <Typography paragraph>
                        {incompleteActivityCount === 1 ? `There is ${incompleteActivityCount} incomplete activity.`: `There are ${incompleteActivityCount} incomplete activities.`}
                    </Typography>
                    <Typography>Are you sure you want to complete this project?</Typography>
                </DialogContent>
                <DialogActions>
                  <StyledButton
                    onClick={() => {
                      setShowCompleteConfirm(false);
                    }}
                  >
                    Cancel
                  </StyledButton>
                  <StyledButton onClick={markAsComplete}>OK</StyledButton>
                </DialogActions>
              </Dialog>
            );
        };

        const button = {
          text: "Mark as Complete",
          icon: "",
          class: null,
        };
        if (dateCompleted) {
          button.text = "Completed ";
          button.icon = <Done />;
          button.class = classes.completedButton;
        }
        const toggleComplete = (e) => {
          if (dateCompleted) {
            setDateCompleted(null);
          } else {
              if (incompleteActivityCount) {
                setShowCompleteConfirm(true);
              } else {
                markAsComplete();
                setClickCoords([e.clientX, e.clientY]);
                setFire(true);
              }
          }
        };

        const markAsComplete = () => {
            setDateCompleted(DateTime.local().toUTC().toString());
            setShowCompleteConfirm(false);
        }
    
        return (
            <Fragment>
                <Button
                    variant="outlined"
                    className={button.class}
                    startIcon={button.icon}
                    onClick={(e) => {
                        toggleComplete(e);
                    }}
                >
                    {button.text}
                </Button>
                {completeConfirmModal()}
            </Fragment>
        );
      };

    const hideModal = () => {
        setName(data.name);
        setColor(data.color);
        setIcon(data.icon);
        setDescription(data.description);
        setAttachments(data.attachments);
        setShowEditProjectModal(false);
        setShowDuplicateProjectModal(false);
        setShowProjectAttachmentsModal(false);
        setProjectId(data._id);
        setDateCompleted(data.dateCompleted);
        setStudentsToInclude(data.students);
        setCategoriesToInclude(data.categories);
    }

    const submitUpdatedProject = (event = null, hideModal = true) => {
        if (event) {
            event.preventDefault();
        };
        const submitData = {
            _id: projectId,
            name: name,
            color: color,
            icon: icon,
            description: description,
            attachments: attachments,
            dateCompleted: dateCompleted,
            students: studentsToInclude,
            categories: categoriesToInclude,
        };
        updateProject(submitData).then((response) => {
            logAction("Updated project", country, location.pathname, submitData); //Mixpanel logging
            let projectIndex = projects.findIndex(project => project._id === projectId);
            let projectsCopy = [...projects];
            projectsCopy[projectIndex].name = name;
            projectsCopy[projectIndex].color = color;
            projectsCopy[projectIndex].icon = icon;
            projectsCopy[projectIndex].description = description;
            projectsCopy[projectIndex].attachments = attachments;
            projectsCopy[projectIndex].dateCompleted = dateCompleted;
            projectsCopy[projectIndex].students = studentsToInclude;
            projectsCopy[projectIndex].categories = categoriesToInclude;
            setProjects([...projectsCopy]);
            //document.getElementById("project_form").reset()

        }).catch(error =>{
            console.log("An error occured during project submission:", error);
        });
        if (hideModal) {
            setShowEditProjectModal(false);
            setShowDuplicateProjectModal(false);
            setShowProjectAttachmentsModal(false);
        }
    }

    const deleteConfirmModal = () => {
        const completeActivityCount = filters[1].length;
        return (
          <Dialog open={showDeleteConfirm}>
            <DialogTitle>Delete</DialogTitle>
            <DialogContent>
                <Typography>
                    {
                        completeActivityCount
                        ? "A project with completed activities cannot be deleted"
                        : "Are you sure you want to delete this project?"
                    }
                </Typography>
            </DialogContent>
            <DialogActions>
              <StyledButton
                onClick={() => {
                  setShowDeleteConfirm(false);
                }}
              >
                    {
                        completeActivityCount
                        ? "OK"
                        : "Cancel"
                    }
              </StyledButton>
                {
                    completeActivityCount
                    ? null
                    : <StyledButton onClick={onDelete}>OK</StyledButton>
                }
            </DialogActions>
          </Dialog>
        );
    };

    return (
        <Dialog fullScreen={screenIsXs ? true : false} fullWidth={true} maxWidth={"md"} open={isOpen}>
            <DialogTitle>
                <div className={classes.dialogTitle}>
                    <IconButton
                        onClick={onClose}
                    >
                        <ArrowBack />
                    </IconButton>
                    <div className={classes.titleText}>{name}</div>
                    {
                        !screenIsXs &&
                        <div>
                            <Tooltip title="Attachments">
                            <IconButton 
                                onClick={() => {
                                    setShowProjectAttachmentsModal(true);
                                }}
                            >
                                <Badge 
                                    badgeContent={attachments.length} 
                                    overlap="rectangular"
                                    color={attachments.length ? "secondary" : "error"}
                                    classes={{
                                        colorError: classes.attachmentCountZero,
                                    }}
                                    showZero 
                                >
                                    <AttachFile />
                                </Badge>
                            </IconButton>
                            </Tooltip>
                            { userPermissions.addActivity &&
                                <Tooltip title="Add Activity">
                                    <IconButton onClick={() => {setShowActivityModal(true)}}>
                                        <Add />
                                    </IconButton>
                                </Tooltip>
                            }
                        </div>
                    }
                    { userPermissions.editProject &&
                        <IconButton onClick={projectMenuOpen}>
                            <MoreVert />
                        </IconButton>
                    }
                    <Menu id="project-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClick={projectMenuClose} onClose={projectMenuClose}>
                    {
                        screenIsXs &&
                        <div>
                            <MenuItem
                                onClick={() => {
                                    setShowProjectAttachmentsModal(true);
                                }}
                            >
                                <Badge 
                                    badgeContent={attachments.length}
                                    overlap="rectangular"
                                    color={attachments.length ? "secondary" : "error"}
                                    classes={{
                                        colorError: classes.attachmentCountZero,
                                    }}
                                    showZero 
                                >
                                    Attachments
                                </Badge>
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    setShowActivityModal(true);
                                }}
                            >
                                Add Activity
                            </MenuItem>
                        </div>
                    }
                        <MenuItem
                            onClick={() => {
                                setShowEditProjectModal(true);
                            }}
                        >
                            Edit
                        </MenuItem>
                        { 
                            isReady && treatment[treatmentName.studentLogin].treatment === "on" //Feature flag
                            ? <MenuItem
                                disabled={!filters[0].length || (filters[0].length && filters[1].length) || filterCountTotal > maxActivitiesForDuplication ? true : false}
                                onClick={() => {
                                    setShowDuplicateProjectModal(true);
                                }}
                            >
                                Duplicate
                            </MenuItem>
                            : null
                        }
                        <MenuItem
                            onClick={() => {
                                setShowDeleteConfirm(true);
                            }}
                        >
                            Delete
                        </MenuItem>
                    </Menu>
                    {deleteConfirmModal()}
                    <ProjectEditModal
                        students={students} 
                        categories={categories} 
                        isOpen={showEditProjectModal} 
                        submitFunction={submitUpdatedProject} 
                        cancelFunction={hideModal}
                        name={name} 
                        setName={setName} 
                        color={color} 
                        setColor={setColor} 
                        icon={icon}
                        setIcon={setIcon}
                        description={description}
                        setDescription={setDescription}
                        dateCompleted={dateCompleted}
                        setDateCompleted={setDateCompleted}
                        studentsToInclude={studentsToInclude}
                        setStudentsToInclude={setStudentsToInclude}
                        categoriesToInclude={categoriesToInclude}
                        setCategoriesToInclude={setCategoriesToInclude}
                        logoUrl={data.logoUrl}
                    />
                    <ProjectDuplicateModal 
                        activities={activities}
                        setActivities={setActivities}
                        students={students} 
                        categories={categories} 
                        projects={projects}
                        setProjects={setProjects}
                        isOpen={showDuplicateProjectModal}
                        setShowDuplicateProjectModal={setShowDuplicateProjectModal}
                        filters={filters}
                        projectId={projectId}
                    />
                    <ProjectAttachmentsModal
                        activities={activities}
                        setActivities={setActivities}
                        students={students}
                        categories={categories} 
                        projects={projects}
                        isOpen={showProjectAttachmentsModal} 
                        submitFunction={submitUpdatedProject} 
                        attachments={attachments}
                        setAttachments={setAttachments}
                        location={location}
                        projectId={projectId}
                        projectStudents={studentsToInclude}
                        projectCategories={categoriesToInclude}
                        setNotificationDetails={setNotificationDetails}
                    />
                    <NewActivity
                        setActivities={setActivities}
                        activities={activities}
                        isOpen={showNewActivityModal}
                        setShowActivityModal={setShowActivityModal}
                        students={students}
                        categories={categories} 
                        projects={projects}
                        projectId={projectId}
                        projectStudents={studentsToInclude}
                        projectCategories={categoriesToInclude}
                        setNotificationDetails={setNotificationDetails}
                    />
                </div>
            </DialogTitle>
            <DialogContent classes={ screenIsXs ? { root: classes.dialogContentRoot } : null }> {/* If screenIsXs override DialogContent's root class to remove padding */}
                <Typography gutterBottom>{data.description}</Typography>
                {!filterCountTotal
                    ? <Alert severity="info">There are currently no activities for this project</Alert>
                    : <Fragment>
                        <StatusTabs 
                            activeTab={activeTab} 
                            setActiveTab={setActiveTab} 
                            filterIndex={0}
                            filterCounts={filterCounts}
                            tabLabels={["Outstanding", "Completed"]}
                        />
                        <ActivitiesList 
                            activities={activities} 
                            setActivities={setActivities} 
                            students={students} 
                            categories={categories} 
                            projects={projects}
                            filterIndex={filterIndex}
                            filters={filters}
                            sortMethod={sortMethod}
                            setNotificationDetails={setNotificationDetails}
                        />
                    </Fragment>
                }
            </DialogContent>
            <DialogActions>
                {userPermissions.editProject && completeButton()}
                <StyledButton onClick={onClose}>
                    Done
                </StyledButton>
            </DialogActions>
            <Confetti fire={fire} setFire={setFire} clickCoords={clickCoords} />
        </Dialog>
    )
}

export default ProjectModal;