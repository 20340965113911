import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        maxWidth: "800px",
        overflowY: "scroll",
        [theme.breakpoints.down('xs')]: {
            overflow: "auto",
        },
    },
  }));

const FilteredContentArea = (props) => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            {props.children}
        </div>
    )
};

export default FilteredContentArea;