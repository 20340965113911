import { BrowserRouter, Route, Redirect } from "react-router-dom";
import React, { useState, useReducer, useEffect } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { SplitFactory } from "@splitsoftware/splitio-react";
import mixpanel from "mixpanel-browser";
import { Helmet } from "react-helmet";

import Header from "./components/navigation/Header.js";
import NavMenu from "./components/navigation/NavMenu.js";
import Spinner from "./components/common/Spinner.js";
import MyWeek from "./containers/myWeek/MyWeek.js";
import LoginNotification from "./components/notifications/LoginNotification.js";
import ActivityNotification from "./components/notifications/ActivityNotification.js";
import Schedule from "./containers/schedule/Schedule.js";
import Progress from "./containers/progress/Progress.js";
import Projects from "./containers/projects/Projects.js";
import Report from "./containers/report/Report.js";
//import Goals from "./containers/goals/Goals.js"; 
import Students from "./containers/students/Students.js";
import Categories from "./containers/categories/Categories.js";
import Account from "./containers/account/Account.js";
import Landing from "./components/auth/Landing.js";
import UserContext from "./UserContext.js";
import { userAuthenticated, setLoggedInUser, getLoggedInUser, getLoggedInUserDetails } from "./services/auth_services.js";
import stateReducer from "./config/state_reducer.js";
import { StateContext } from "./config/store.js";
import { getFaIcons } from "./utils/FaIcons.js";
import { logIdentify } from "./log.js";
import { refreshActivities, refreshStudents, refreshCategories, refreshProjects, refreshGoals, refreshRoutineItems } from "./services/data_refresh.js";
import { getAccountDetail } from "./services/account_services.js";

const mixPanelKey = "9e3d89a0635c4d0bab7455102e75bbb8"
const splitFactoryKey = "gndqshph9angic2ef6m5ihtt73t74vti1l48"
mixpanel.init(mixPanelKey);

const faIcons = getFaIcons();
library.add(faIcons)

const App = () => {
  const [pixelRatio, setPixelRatio] = useState(0);
  const [country, setCountry] = useState("");
  const [userId, setUserId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [emailVerified, setEmailVerified] = useState(false);
  const [showTutorial, setShowTutorial] = useState(false);
  const [hasAcceptedLatestTerms, setHasAcceptedLatestTerms] = useState(0);
  const [dateJoined, setDateJoined] = useState("");
  const [studentId, setStudentId] = useState("");
  const [userPermissions, setUserPermissions] = useState({});
  const [debugMode, setDebugMode] = useState(false);
  const [primaryFirstName, setPrimaryFirstName] = useState("");
  const userState = {
    pixelRatio,
    setPixelRatio,
    userId, 
    setUserId,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    username,
    setUsername,
    email,
    setEmail,
    country,
    setCountry,
    emailVerified,
    setEmailVerified,
    showTutorial,
    setShowTutorial,
    hasAcceptedLatestTerms,
    setHasAcceptedLatestTerms,
    dateJoined,
    setDateJoined,
    studentId, 
    setStudentId,
    userPermissions,
    setUserPermissions,
    debugMode,
    setDebugMode,
    primaryFirstName,
    setPrimaryFirstName
  };
  const [activities, setActivities] = useState([]);
  const [students, setStudents] = useState([]);
  const [categories, setCategories] = useState([]);
  const [projects, setProjects] = useState([]);
  const [goals, setGoals] = useState([]);
  const [routineItems, setRoutineItems] = useState([]);
  const [navOpen, setNavOpen] = useState(false);
  const initialState = { loggedInUser: null };
  const [store, dispatch] = useReducer(stateReducer, initialState);
  const drawerWidth = 240;
  const [bannerClearance, setBannerClearance] = useState(0);
  const [notificationDetails, setNotificationDetails] = useState({});
  const [hasAccess, setHasAccess] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    setLoading(true); 
    try { 
      await userAuthenticated();      
      let loggedInUser = getLoggedInUser();
      if (loggedInUser !== null) {
        let accountDetail = await getAccountDetail();
        setHasAccess(accountDetail.hasAccess);
        dispatch({ type: "setLoggedInUser", data: loggedInUser });
        let loggedInUserDetails = JSON.parse(getLoggedInUserDetails());
        logIdentify(loggedInUserDetails); // Mixpanel logging
        setUsername(loggedInUserDetails.username);
        setUserId(loggedInUserDetails.id);
        setFirstName(loggedInUserDetails.hasOwnProperty('firstName') ? loggedInUserDetails.firstName : "")
        setLastName(loggedInUserDetails.hasOwnProperty('lastName') ? loggedInUserDetails.lastName : "")
        setEmail(loggedInUserDetails.hasOwnProperty('email') ? loggedInUserDetails.email : "");
        setCountry(loggedInUserDetails.country);
        setEmailVerified(loggedInUserDetails.emailVerified);
        setShowTutorial(loggedInUserDetails.showTutorial);
        setHasAcceptedLatestTerms(loggedInUserDetails.hasAcceptedLatestTerms);
        setDateJoined(loggedInUserDetails.dateJoined);
        setStudentId(loggedInUserDetails.studentId);
        setUserPermissions(loggedInUserDetails.userPermissions);
        setDebugMode(loggedInUserDetails.debugMode);
        setPrimaryFirstName(accountDetail.primaryFirstName);

        refreshActivities(setActivities);
        refreshStudents(setStudents);
        refreshCategories(setCategories);
        refreshProjects(setProjects);
        refreshGoals(setGoals);
        refreshRoutineItems(setRoutineItems);    
        }
      } catch (error) {
        console.log("got an error trying to check authenticated user:", error);
        setHasAccess(false);
        setLoggedInUser(null);
        dispatch({ type: "setLoggedInUser", data: null });
      };
      setLoading(false);
  }

  let loggedIn = getLoggedInUser();
  
  return (
    <div>
      <Helmet>
        <script>
          {`
            (function(w){
              w.fpr=w.fpr||function(){w.fpr.q = w.fpr.q||[];w.fpr.q[arguments[0]=='set'?'unshift':'push'](arguments);
              };
            })(window);
            fpr("init", {cid:"zwgbgxb6"});
            fpr("click");
          `}
        </script>
        <script src="https://cdn.firstpromoter.com/fpr.js" async></script>
      </Helmet>
      <StateContext.Provider value={{ store, dispatch }}>
        <UserContext.Provider value={userState}>
          <BrowserRouter>
            {
            loading ? 
            <>
              <Spinner open={loading} />
            </>
            :
            loggedIn && hasAccess ?
              <>
                {/* user logged in and has access so can route normally */}
                <SplitFactory config={{core: {authorizationKey: splitFactoryKey, key: loggedIn}}}>
                  <Route path="/" render={() => <Header navOpen={navOpen} setNavOpen={setNavOpen} students={students} goals={goals} setGoals={setGoals} setBannerClearance={setBannerClearance} />} />
                  <Route path="/" render={() => <NavMenu navOpen={navOpen} setNavOpen={setNavOpen} drawerWidth={drawerWidth} bannerClearance={bannerClearance} />} />
                  <Route path="/" render={() => <LoginNotification projects={projects} />} />
                  <Route exact path="/myweek" render={() => <MyWeek activities={activities} students={students} categories={categories} setActivities={setActivities} projects={projects} setProjects={setProjects} navOpen={navOpen} drawerWidth={drawerWidth} bannerClearance={bannerClearance} setNotificationDetails={setNotificationDetails} routineItems={routineItems} setRoutineItems={setRoutineItems} />} />
                  <Route exact path="/schedule" render={() => <Schedule activities={activities} students={students} categories={categories} setActivities={setActivities} projects={projects} setProjects={setProjects} navOpen={navOpen} drawerWidth={drawerWidth} bannerClearance={bannerClearance} setNotificationDetails={setNotificationDetails} />} />
                  <Route exact path="/progress" render={() => <Progress activities={activities} students={students} categories={categories} setActivities={setActivities} projects={projects} setProjects={setProjects} navOpen={navOpen} drawerWidth={drawerWidth} bannerClearance={bannerClearance} setNotificationDetails={setNotificationDetails} />} />
                  <Route exact path="/projects" render={() => <Projects activities={activities} students={students} categories={categories} setActivities={setActivities} projects={projects} setProjects={setProjects} navOpen={navOpen} drawerWidth={drawerWidth} bannerClearance={bannerClearance} setNotificationDetails={setNotificationDetails} />} />
                  {/* <Route exact path="/goals" render={() => <Goals students={students} goals={goals} setGoals={setGoals} navOpen={navOpen} drawerWidth={drawerWidth} bannerClearance={bannerClearance} /> } /> */}
                  { userPermissions.reporting && <Route exact path="/report" render={() => <Report activities={activities} students={students} categories={categories} setActivities={setActivities} projects={projects} setProjects={setProjects} navOpen={navOpen} drawerWidth={drawerWidth} bannerClearance={bannerClearance} setNotificationDetails={setNotificationDetails} />} />}
                  { userPermissions.manageStudents && <Route exact path="/students" render={() => <Students students={students} setStudents={setStudents} navOpen={navOpen} drawerWidth={drawerWidth} bannerClearance={bannerClearance} />} />}
                  { userPermissions.manageCategories && <Route exact path="/categories" render={() => <Categories categories={categories} setCategories={setCategories} navOpen={navOpen} drawerWidth={drawerWidth} bannerClearance={bannerClearance} />} />}
                  { userPermissions.manageAccount && <Route exact path="/account" render={() => <Account navOpen={navOpen} drawerWidth={drawerWidth} bannerClearance={bannerClearance} />} /> }
                  <Route exact path="/landing" render={() => <Redirect to="/myweek" /> } />
                  <Route path="*" render={() => <Redirect to="/myweek" /> } />
                </SplitFactory>
              </>
            :
              loggedIn && !hasAccess ? 
                <>
                  {/* user logged in but doesn't have access */}
                  <SplitFactory config={{core: {authorizationKey: splitFactoryKey, key: loggedIn}}}>
                    <Route path="/" render={() => <Header navOpen={navOpen} setNavOpen={setNavOpen} students={students} goals={goals} setGoals={setGoals} setBannerClearance={setBannerClearance} />} />
                    <Route path="/" render={() => <NavMenu navOpen={navOpen} setNavOpen={setNavOpen} drawerWidth={drawerWidth} bannerClearance={bannerClearance} />} />
                    <Route path="/" render={() => <LoginNotification />} />
                    { userPermissions.manageAccount && <Route path="/" render={() => <Account navOpen={navOpen} drawerWidth={drawerWidth} bannerClearance={bannerClearance} />} /> }
                    <Route path="*" render={() => <Redirect to="/account" /> } />
                  </SplitFactory>
                </>
              :
                <>
                  {/* user not logged in */}
                  <Route exact path="/landing" render={() =><Landing/>} />
                  <Route path="*" render={() => <Redirect to="/landing" /> } />
                </>
            }
          </BrowserRouter>
          <ActivityNotification notificationDetails={notificationDetails} />
        </UserContext.Provider>
      </StateContext.Provider>
    </div>
  );
};

export default App;
