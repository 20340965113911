import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import { Typography, Chip, Grid, useTheme, useMediaQuery } from '@material-ui/core';
import ProjectSelectionFilter from './ProjectSelectionFilter.js';
import { useTreatments, SplitContext } from "@splitsoftware/splitio-react";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "row",
    },
    typeChipDraft: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.info.main,
        position: "absolute",
        top: 0, 
        left: 0, 
        margin: theme.spacing(0.5)
    },
    categoryChip: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(0.5),
        backgroundColor: theme.palette.primary.shade[100],
        color: theme.palette.grey[900],
    },
    tagChip: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(0.5),
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.grey[900],
        fontStyle: "italic"
    },
    card: {
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(1),
        marginRight: theme.spacing(2),
        '&:hover': {
            cursor: "pointer",
            backgroundColor: theme.palette.secondary.shade[50],
        },
    },
    cardTop: {
        height: 145,
        position: "relative",
        display: "inline-block"
    },
    projectPlanLogo: {
        height: 129,
        borderRadius: theme.shape.borderRadius, // Rounded corners
        boxShadow: theme.shadows[5], // Dropshadow
        display: "block"
    },
    title: {
        height: "5em"
    },
    rowNoWrap: {
        display: "flex",
        whiteSpace: "nowrap",
        overflowX: "hidden"
    },
    author: {
        fontStyle: "italic"
    },
    projectsContainer: {
        height: "calc(90vh - 120px)",
        maxHeight: "calc(90vh - 120px)",
        width: "100%",
        overflowY: "auto",
        [theme.breakpoints.down('xs')]: {
            height: "auto",
            maxHeight: "none",
        },
    }
}));

const ProjectSelection = ({projectPlans, navForward, drawerOpen, setDrawerOpen}) => {

    const classes = useStyles();
    const theme = useTheme();
    const screenIsXs = useMediaQuery(theme.breakpoints.down("xs"));
    const [activeTab, setActiveTab] = useState("All");
    const [filterAuthor, setFilterAuthor] = useState("All");

    const { isReady } = useContext(SplitContext);
    const treatmentName = {
      projectImportDrafts: "project_import_drafts"
    };
    const treatment = useTreatments([treatmentName.projectImportDrafts]);

    const handleClick = (id) => {
          navForward(id);
    };

    return (
        <div className={classes.root}>
            <ProjectSelectionFilter projectPlans={projectPlans} activeTab={activeTab} setActiveTab={setActiveTab} setFilterAuthor={setFilterAuthor} drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} drawerStyle={screenIsXs} /> 
            <div className={classes.projectsContainer}>
                <Grid container>
                    {projectPlans.filter((projectPlan) => {
                        if (projectPlan.status === "active") {
                            if (filterAuthor === "All") {
                                return true;
                            } else {
                                return projectPlan.author === filterAuthor;
                            }
                        } else if (isReady && treatment[treatmentName.projectImportDrafts].treatment === "on" && projectPlan.status === "draft") {
                            if (filterAuthor === "All") {
                                return true;
                            } else {
                                return projectPlan.author === filterAuthor;
                            }
                        } else {
                            return false;
                        }
                    })
                    .map((projectPlan, i) => {
                        const listItem = <Grid 
                                item 
                                xs={12}
                                sm={6}
                                md={4}
                                lg={3}
                                key={i} 
                                onClick={() => handleClick(projectPlan._id)}
                            >       
                                <div className={classes.card}>
                                    <div className={classes.cardTop}>
                                        <img className={classes.projectPlanLogo} src={projectPlan.logoUrl} alt={projectPlan.name} />
                                        {projectPlan.status === "draft" && <Chip size="small" label="Draft" className={classes.typeChipDraft} />}
                                    </div>
                                    <div className={classes.title}>
                                        <Typography variant="subtitle2">{projectPlan.name}</Typography>
                                        <Typography variant="body2" className={classes.author}>By {projectPlan.author}</Typography>
                                    </div>
                                    <div className={classes.rowNoWrap}>
                                        {projectPlan.defaultCategories.map((category, i) => {
                                            if (i < 1) {
                                                return <Chip size="small" label={category.name} key={i} className={classes.categoryChip} />
                                            } else if (i === 1) {
                                                return <Chip size="small" label={"+ more"} key={i} className={classes.categoryChip} />
                                            } else {
                                                return null
                                            }
                                        })}
                                    </div>
                                    <div className={classes.rowNoWrap}>
                                        {projectPlan.tags.map((tag, i) => {
                                            if (i < 1) {
                                                return <Chip size="small" label={tag.name} key={i} className={classes.tagChip} />
                                            } else if (i === 1) {
                                                return <Chip size="small" label={"+ more"} key={i} className={classes.tagChip} />
                                            } else {
                                                return null
                                            }
                                        })}
                                    </div>
                                </div>
                            </Grid>
                        return listItem;
                    })
                    }
                </Grid>
            </div>
        </div>
    )
}

export default ProjectSelection;