import React, { useState, useEffect, useContext } from 'react';
import ActivitiesList from '../../components/activities/ActivitiesList.js';
import NewActivityButton from '../../components/activities/NewActivityButton.js';
import StudentFilter from '../../components/common/StudentFilter.js';
import FilterList from '../../components/activities/FilterList.js';
import SearchBar from '../../components/activities/SearchBar.js';
import ExportToPdf from '../../components/activities/ExportToPdf.js';
import PageListDetail from '../../components/layout/PageListDetail.js';
import { getFilteredData } from '../activities/FilterData.js';
import { getAllActivities } from '../../services/activity_services.js';
import FilterPane from '../../components/layout/FilterPane.js';
import FilteredContentArea from '../../components/layout/FilteredContentArea.js';
import TopBar from '../../components/layout/TopBar.js';
import UserContext from '../../UserContext.js';

const Schedule = ({ activities, setActivities, students, categories, projects, navOpen, drawerWidth, bannerClearance, setNotificationDetails }) => {

    const [selectedStudent, setSelectedStudent] = useState(0);
    const [filterIndex, setFilterIndex] = useState(0);
    const [filterText, setFilterText] = useState('');
    const { userPermissions } = useContext(UserContext);

    const filters = [
        getFilteredData(activities, {
            student: selectedStudent,
            completed: 0, 
            date: {
                mode: 0,
                from: 0,
                to: 0
            }
        }),
        getFilteredData(activities, {
            student: selectedStudent,
            completed: 0, 
            date: {
                mode: 0,
                from: 1,
                to: 7
            }
        }),
        getFilteredData(activities, {
            student: selectedStudent,
            completed: 0, 
            date: {
                mode: 0,
                from: 8,
                to: 30
            }
        }),
        getFilteredData(activities, {
            student: selectedStudent,
            completed: 0, 
            date: {
                mode: 0,
                from: 31,
                to: 999
            }
        }),
        getFilteredData(activities, {
            student: selectedStudent,
            completed: 0, 
            date: {
                mode: 0,
                from: -999,
                to: -1
            }
        }),
        getFilteredData(activities, {
            student: selectedStudent,
            completed: 0, 
            date: {
                mode: 2,
            }
        }),
    ]

    const filterLabels = [
        "Today",
        "Next 7 Days",
        "Next 30 Days",
        "Future",
        "Past",
        "Unscheduled",
    ];

    const filterListItems = filters.map((filter, i) => (
        {
            key: filterLabels[i],
            label: filterLabels[i],
            count: [filter.length],
            onClick: () => { setFilterIndex(i) }
        }
    ))

    useEffect(() => {
        getAllActivities()
            .then((response) => setActivities(response.data))
            .catch((error) => console.log(error))
    }, [setActivities]);

    return (
        <PageListDetail navOpen={navOpen} drawerWidth={drawerWidth} bannerClearance={bannerClearance}>
            <FilterPane>
                { userPermissions.filterStudents && 
                    <StudentFilter 
                        students={students} 
                        selectedStudent={selectedStudent} 
                        setSelectedStudent={setSelectedStudent} 
                    />
                }
                <FilterList filterListItems={filterListItems} setFilterIndex={setFilterIndex} />
            </FilterPane>
            <FilteredContentArea>
                <TopBar>
                    <SearchBar filterText={filterText} setFilterText={setFilterText} />
                    { userPermissions.reporting &&
                        <ExportToPdf 
                            students={students} 
                            categories={categories}
                            projects={projects}
                            filterIndex={filterIndex}
                            filters={filters}
                            filterText={filterText}
                            sortMethod="date" 
                        />
                    }
                </TopBar>
                <ActivitiesList 
                    activities={activities} 
                    setActivities={setActivities} 
                    students={students} 
                    categories={categories} 
                    projects={projects}
                    filterIndex={filterIndex}
                    filters={filters}
                    filterText={filterText}
                    selectedStudent={selectedStudent}
                    sortMethod="date" 
                    setNotificationDetails={setNotificationDetails}
                />
            </FilteredContentArea>
            { userPermissions.addActivity &&
                <NewActivityButton 
                    setActivities={setActivities}
                    activities={activities}
                    students={students}
                    categories={categories} 
                    projects={projects}
                    setNotificationDetails={setNotificationDetails}
                />
            }
        </PageListDetail>
    )
}

export default Schedule;
