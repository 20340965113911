import React, { useState, useContext, Fragment } from "react";
import { addStudent } from "../../services/student_services.js";
import StudentModal from "./StudentModal.js";
import StyledButton from "../common/StyledButton.js";
import { logAction } from "../../log.js";
import { useLocation } from "react-router-dom";
import UserContext from "../../UserContext.js";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  addButton: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const NewStudent = ({ students, setStudents }) => {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [color, setColor] = useState("#d96375");
  const [email, setEmail] = useState("");
  const [allowLogin, setAllowLogin] = useState(false);
  const location = useLocation();
  const { country } = useContext(UserContext);
  const [showAddStudentModal, setShowAddStudentModal] = useState(false);

  const submitNewStudent = (event) => {
    event.preventDefault();

    const data = {
      name: name,
      color: color,
      email: email,
      allowLogin: allowLogin,
    };
    addStudent(data)
      .then((response) => {
        logAction("Created student", country, location.pathname, response.data.data); //Mixpanel logging
        setStudents([...students, response.data.data]);
        document.getElementById("form").reset();
      })
      .catch((error) => {
        console.log("An error occured during student submission:", error);
      });
    hideModal();
  };

  const hideModal = (event) => {
    setName("");
    setColor("#d96375");
    setEmail("");
    setAllowLogin(false);
    setShowAddStudentModal(false);
  };

  return (
    <Fragment>
      <div className={classes.addButton}>
        <StyledButton
          onClick={() => {
            setShowAddStudentModal(true);
          }}
        >
          Add
        </StyledButton>
      </div>
      <StudentModal isOpen={showAddStudentModal} cancelFunction={hideModal} submitFunction={submitNewStudent} name={name} setName={setName} color={color} setColor={setColor} email={email} setEmail={setEmail} allowLogin={allowLogin} setAllowLogin={setAllowLogin} />
    </Fragment>
  );
};

export default NewStudent;
