import React from 'react';
import ReactConfetti from 'react-confetti'
import { useWindowSize } from '@react-hook/window-size'
import { useTheme, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    confetti: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
    }
}));

const Confetti = ({fire, setFire, clickCoords}) => {
    const classes = useStyles();
    const theme = useTheme();
    const [width, height] = useWindowSize()

    return (
        !fire
        ? null
        : <div className={classes.confetti}
            onClick={() => {setFire(false)}}
        >
            <ReactConfetti 
                width={width}
                height={height}
                numberOfPieces={30}
                initialVelocityX={8}
                initialVelocityY={20}
                tweenDuration={1}
                gravity={0.8}
                recycle={false}
                confettiSource={{ // 50px to the left and right of the clicked location
                    x: clickCoords[0] - 50,
                    y: clickCoords[1],
                    w: 100
                }}
                drawShape={ctx => { // small rectangles
                    ctx.beginPath();
                    ctx.rect(1, 1, 5, 5); // x, y, width, height
                    ctx.fill();
                    ctx.closePath();
                }}
                colors={[
                    theme.palette.complete.main,
                    theme.palette.complete.shade[200],
                    theme.palette.primary.shade[200],
                    theme.palette.secondary.main,
                ]}
                onConfettiComplete={() => setFire(false)}
            />
        </div>
    )
}

export default Confetti;