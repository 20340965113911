import React, {Fragment, useState, useContext} from 'react';
import { TableCell } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { makeStyles } from "@material-ui/core";
import RoutineItemModal from "./RoutineItemModal.js";
import { logAction } from "../../log.js";
import UserContext from "../../UserContext.js";
import { useLocation } from "react-router-dom";
import {addRoutineItem} from '../../services/routine_services.js';

const useStyles = makeStyles((theme) => ({
    tableCell: {
        cursor: "pointer",
    },
    addIcon: {
        height: theme.spacing(4),
        width: theme.spacing(4),
        color: theme.palette.grey[600]
    },
}));

const NewRoutineItem = ({studentId, dayIndex, routineItems, setRoutineItems, selectedStudent, setSelectedStudent, selectedDay, setSelectedDay, categoriesToInclude, setCategoriesToInclude, students, categories }) => {

    const classes = useStyles();
    const location = useLocation();
    const { country } = useContext(UserContext);
    const [showRoutineItemModal, setShowRoutineItemModal] = useState(false);

    const handleClick = (studentId, dayIndex) => {
        setSelectedStudent(studentId);
        setSelectedDay(dayIndex);
        setShowRoutineItemModal(true);
    }

    const hideModal = () => {
        setShowRoutineItemModal(false);
        setSelectedStudent("");
        setSelectedDay(null);
        setCategoriesToInclude([]);
    }

    const submitRoutineItem = (e) => {
        e.preventDefault();
        if (categoriesToInclude.length) {
            const data = {
                student: selectedStudent,
                day: selectedDay,
                categories: categoriesToInclude,
                isLoop: false,
                loopName: "",
            };
            addRoutineItem(data).then((response) => {
                logAction("Created routine item", country, location.pathname, response.data.data); //Mixpanel logging
                setRoutineItems([...routineItems, response.data.data]);
                //document.getElementById("form").reset()
            }).catch(error =>{
                console.log("An error occured during student submission:", error);
            });
        };
        hideModal();
    };

    return (
        <Fragment>
            <TableCell
                className={classes.tableCell}
                onClick={() => {handleClick(studentId, dayIndex)}}
            >
                <Add className={classes.addIcon} />
            </TableCell>
            <RoutineItemModal 
                showRoutineItemModal={showRoutineItemModal} 
                selectedStudent={selectedStudent}
                selectedDay={selectedDay}
                categoriesToInclude={categoriesToInclude}
                setCategoriesToInclude={setCategoriesToInclude}
                students={students}
                categories={categories}
                cancelFunction={hideModal}
                submitFunction={submitRoutineItem}
            />
        </Fragment>
    )
}

export default NewRoutineItem;