import api from "../config/api.js";

export const getStudents = async () => {
  try {
    return await api.get("students/getStudents");
  } catch (error) {
    if (typeof error.response !== "undefined") {
      if (error.response.status === 401) {
        window.location.href = "/API/auth/login";
      }
    }
    console.log(error);
    throw error;
  }
};

export const addStudent = async (data) => {
  try {
    return await api.post("students/newStudent", data);
  } catch (error) {
    if (typeof error.response !== "undefined") {
      if (error.response.status === 401) {
        window.location.href = "/API/auth/login";
      }
    }
    console.log(error);
    throw error;
  }
};

export const updateStudent = async (data) => {
  try {
    return await api.post("students/updateStudent", data);
  } catch (error) {
    if (typeof error.response !== "undefined") {
      if (error.response.status === 401) {
        window.location.href = "/API/auth/login";
      }
    }
    console.log(error);
    throw error;
  }
};

export const deleteStudent = async (studentId) => {
  try {
    return await api.delete("students/deleteStudent", { data: studentId });
  } catch (error) {
    if (typeof error.response !== "undefined") {
      if (error.response.status === 401) {
        window.location.href = "/API/auth/login";
      }
    }
    console.log(error);
    throw error;
  }
};
