import api from "../config/api.js";

export const getRoutineItems = async () => {
  try {
    return await api.get("routine/getRoutineItems");
  } catch (error) {
    if (typeof error.response !== "undefined") {
      if (error.response.status === 401) {
        window.location.href = "/API/auth/login";
      }
    }
    console.log(error);
    throw error;
  }
};

export const addRoutineItem = async (data) => {
  try {
    return await api.post("routine/newRoutineItem", data);
  } catch (error) {
    if (typeof error.response !== "undefined") {
      if (error.response.status === 401) {
        window.location.href = "/API/auth/login";
      }
    }
    console.log(error);
    throw error;
  }
};

export const updateRoutineItem = async (data) => {
  try {
    return await api.post("routine/updateRoutineItem", data);
  } catch (error) {
    if (typeof error.response !== "undefined") {
      if (error.response.status === 401) {
        window.location.href = "/API/auth/login";
      }
    }
    console.log(error);
    throw error;
  }
};

export const deleteRoutineItem = async (id) => {
  try {
    return await api.delete("routine/deleteRoutineItem", { data: id });
  } catch (error) {
    if (typeof error.response !== "undefined") {
      if (error.response.status === 401) {
        window.location.href = "/API/auth/login";
      }
    }
    console.log(error);
    throw error;
  }
};
