import React, { Fragment, useState, useContext } from 'react';
import NewProject from "../../containers/projects/NewProject.js";
import { Fab, useMediaQuery, useTheme, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { logAction } from "../../log.js";
import { useLocation } from "react-router-dom";
import UserContext from "../../UserContext.js";

const useStyles = makeStyles(theme => ({
    button: {
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1),
        background: 'linear-gradient(135deg, rgba(219,90,107,1) 0%, rgba(245,143,132,1) 100%)', //#db5a6b Gradient colours from http://bootflat.github.io/color-picker-pink.html //#f58f84
        color: theme.palette.common.white,
        '&:hover': {
            background: 'linear-gradient(135deg, rgba(219,53,74,1) 0%, rgba(245,105,90,1) 100%)'
        },
    },
}));

const NewProjectButton = ({ activities, setActivities, students, categories, projects, setProjects }) => {

    const classes = useStyles();
    const theme = useTheme();
    const location = useLocation();
    const { country } = useContext(UserContext);
    const screenIsMd = useMediaQuery(theme.breakpoints.down('md')); 
    const [showAddProjectModal, setShowAddProjectModal] = useState(false);

    return (
        <Fragment>
            <Tooltip title="Add Project">
                <Fab 
                    variant={screenIsMd ? "circular" : "extended"}
                    size={"large"}
                    className={classes.button}
                    onClick={() => {
                        logAction("Clicked new project button", country, location.pathname);
                        setShowAddProjectModal(true);
                    }}>
                    <Add />
                    {screenIsMd ? null : "Add Project"}
                </Fab>
            </Tooltip>
            <NewProject 
                activities={activities} 
                setActivities={setActivities} 
                students={students} 
                categories={categories} 
                projects={projects} 
                setProjects={setProjects} 
                showAddProjectModal={showAddProjectModal} 
                setShowAddProjectModal={setShowAddProjectModal} 
            />
        </Fragment>
    )
}

export default NewProjectButton;