function wordCount (s) {
    const noHtml = s.replace(/(<([^>]+)>)/ig,""); //string html tags
    const paragraphs = noHtml.split(/\r?\n/); //split into paragraphs
    let count = 0;
    paragraphs.forEach(paragraph => {
        count += paragraph.split(' ').length;
    });
    return count;
};

export { wordCount };