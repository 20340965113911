import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import StyledButton from "./StyledButton.js";

const SimpleDialog = (props) => {

    return (
        <Dialog open={props.open} onClose={props.onClose} fullScreen={props.fullScreen}>
            <DialogTitle>
                {props.title}
            </DialogTitle>
            <DialogContent>
                {props.children}
            </DialogContent>
            <DialogActions>
                {props.hasOwnProperty('buttonSecondary') ? <StyledButton onClick={props.buttonSecondary.onClick}>{props.buttonSecondary.label}</StyledButton> : null}
                {props.hasOwnProperty('buttonPrimary') ? <StyledButton onClick={props.buttonPrimary.onClick}>{props.buttonPrimary.label}</StyledButton> : null}
            </DialogActions>
        </Dialog>
    )
}

export default SimpleDialog;