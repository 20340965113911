import React from 'react';
import { TextField, Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    wrapper: {
        display: "flex",
        width: "100%"
    },
    textFilterInput: {
        width: "100%",
        paddingRight: theme.spacing(1),
    },
}));

const SearchBar = ({ filterText, setFilterText }) => {

    const classes = useStyles();

    const handleCancelClick = () => {
        const textFilterField = document.getElementById("textFilter");
        textFilterField.value = '';
        setFilterText('');
    };

    const showCancelButton = () => {
        if (filterText.length > 0) {
            return (
                <Box>
                    <Button color="primary" onClick={handleCancelClick}>
                        Cancel
                    </Button>
                </Box>
            )
        }
    }

    return (
        <div className={classes.wrapper}>
            <TextField className={classes.textFilterInput} onChange={e => setFilterText(e.target.value)} id="textFilter" label="Search" variant="outlined" size="small" />
            { showCancelButton() }
        </div>
    )
}

export default SearchBar;