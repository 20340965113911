import api from "../config/api.js";

export const getAllActivities = async () => {
  try {
    return await api.get("activities/getActivitiesByUser");
  } catch (error) {
    if (typeof error.response !== "undefined") {
      if (error.response.status === 401) {
        window.location.href = "/API/auth/login";
      }
    }
    console.log(error);
    throw error;
  }
};

export const submitNewActivity = async (data) => {
  try {
    return await api.post("activities/createActivity", data);
  } catch (error) {
    if (typeof error.response !== "undefined") {
      if (error.response.status === 401) {
        window.location.href = "/API/auth/login";
      }
    }
    console.log(error);
    throw error;
  }
};

export const submitNewActivities = async (data) => {
  const activities = data.activityArray;
  const promises = activities.map(async (activity) => {
    try {
      return await api.post("activities/createActivity", activity);
    } catch (error) {
      if (typeof error.response !== "undefined") {
        if (error.response.status === 401) {
          window.location.href = "/API/auth/login";
        }
      }
      console.log(error);
      throw error;
    };
  });
  const responses = await Promise.all(promises);
  const newActivities = responses.map((response) => {
    return response.data.data;
  })
  return newActivities;
};

export const updateActivity = async (data) => {
  try {
    return await api.post("activities/updateActivity", data);
  } catch (error) {
    if (typeof error.response !== "undefined") {
      if (error.response.status === 401) {
        window.location.href = "/API/auth/login";
      }
    }
    console.log(error);
    throw error;
  }
};

export const updateActivities = async (data) => {
  const activities = data.activityArray;
  const promises = activities.map(async (activity) => {
    try {
      return await api.post("activities/updateActivity", activity);
    } catch (error) {
      if (typeof error.response !== "undefined") {
        if (error.response.status === 401) {
          window.location.href = "/API/auth/login";
        }
      }
      console.log(error);
      throw error;
    };
  });
  const responses = await Promise.all(promises);
  const updatedActivities = responses.map((response) => {
    return response.data.data;
  })
  return updatedActivities;
};

export const deleteActivity = async (data) => {
  try {
    return await api.delete("activities/deleteActivity", { data: data });
  } catch (error) {
    if (typeof error.response !== "undefined") {
      if (error.response.status === 401) {
        window.location.href = "/API/auth/login";
      }
    }
    console.log(error);
    throw error;
  }
};

export const getActivityById = async (activityId) => {
  try {
    return await api.post("activities/getActivityById", {activityId: activityId});
  } catch (error) {
    if (typeof error.response !== "undefined") {
      if (error.response.status === 401) {
        window.location.href = "/API/auth/login";
      }
    }
    console.log(error);
    throw error;
  }
};

export const reorderActivities = async (activities) => {
  try {
    return await api.post("activities/reorderActivities", {activities: activities});
  } catch (error) {
    if (typeof error.response !== "undefined") {
      if (error.response.status === 401) {
        window.location.href = "/API/auth/login";
      }
    }
    console.log(error);
    throw error;
  }
};
