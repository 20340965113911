import { Button, Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import logoPurple from "../../assets/images/logopurple170.png";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "50px",
  },
  logo: {
    paddingBottom: "50px",
  },
  button: {
    marginTop: "40px",
  },
}));

const Landing = () => {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <img className={classes.logo} src={logoPurple} alt="Freely" />
      <Typography variant={"h6"} gutterBottom>
        You are logged out
      </Typography>
      <Typography gutterBottom>
        See you again soon!
      </Typography>
      <Button className={classes.button} variant="contained" color="primary" href={"/API/auth/login"}>
        Log in
      </Button>
    </Container>
  );
};

export default Landing;