import React from 'react';
import { MenuItem, FormControl, Select, InputLabel } from '@material-ui/core';
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    fullWidth: {
        width: "100%",
    },
}));

const CategorySelectorSingle = ({ categories, categoryMap, setCategoryMap, index }) => {

    const classes = useStyles();

    let selectableCategoryList = [...categories];

    const handleChange = (event) => {
      const category = categories.filter((category) => category._id === event.target.value)
      const categoryName = category[0].name
      const categoryMapCopy = [...categoryMap]
      categoryMapCopy[index].userCategory = {
        id: event.target.value,
        name: categoryName
      }
      setCategoryMap([...categoryMapCopy])
    };

  const listItems = selectableCategoryList.map((category, i) => {
      return (
        <MenuItem key={i} value={category._id}>
            {category.name}
        </MenuItem>
      )
  });

  return (
      <FormControl className={classes.fullWidth}>
          <InputLabel>Category</InputLabel>
          <Select 
            name={"selectedCategory"} 
            value={categoryMap[index].userCategory.id}
            onChange={handleChange} 
          >
              {listItems}
          </Select>
      </FormControl>
  );
};

export default CategorySelectorSingle;