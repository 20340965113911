import React from "react";
import { makeStyles, TextField, IconButton, InputAdornment } from "@material-ui/core";
import { Clear } from '@material-ui/icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  leftButton: {
    marginRight: theme.spacing(1)
  },
  buttonHidden: {
    visibility: "hidden",
  },
  clearButton: {
    padding: theme.spacing(0.5)
  },
  submitButtonWrapper: {
    display: "flex",
    alignItems: "center",
  },
  submitButton: {
    height: theme.spacing(5),
    width: theme.spacing(5),
    marginLeft: theme.spacing(0.5)
  },
  submitIcon: {
    color: theme.palette.secondary.shade[300],
    '&:hover': {
      color: theme.palette.secondary.shade[400]
    },
  }
}));

const AddComment = ({commentText, setCommentText, submitFunction}) => {
    const classes = useStyles();

    const onCommentTextChange = (e) => {
      setCommentText(e.target.value)
    }
  
    const onCommentCancel = () => {
      setCommentText('')
    }

    return (
      <div className={classes.root}>
        <TextField 
          variant="outlined" 
          margin="dense" 
          placeholder="Add a comment..." 
          value={commentText} 
          onChange={onCommentTextChange}
          multiline
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position="end">
              {
                !commentText
                ? null
                : <IconButton 
                  className={classes.clearButton}
                  onClick={onCommentCancel}
                >
                  <Clear />
                </IconButton>
              }
            </InputAdornment>
          }}
        />
        {
          !commentText
          ? null
          : <div className={classes.submitButtonWrapper}>
            <IconButton 
              onClick={submitFunction}
              className={classes.submitButton}
            >
              <FontAwesomeIcon icon="arrow-circle-up" className={classes.submitIcon} />
            </IconButton>
          </div>
        }
      </div>
    )
};

export default AddComment;