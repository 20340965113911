import React, { useState, Fragment, useContext } from "react";
import { deleteStudent } from "../../services/student_services.js";
import { Dialog, DialogActions, DialogContent, DialogTitle, ListItem, ListItemSecondaryAction, Typography, IconButton, Tooltip } from "@material-ui/core";
import StyledButton from "../common/StyledButton.js";
import { Clear } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";
import { updateStudent } from "../../services/student_services.js";
import StudentModal from "../../components/students/StudentModal.js";
import { logAction } from "../../log.js";
import UserContext from "../../UserContext.js";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    borderBottomColor: theme.palette.divider,
    cursor: "pointer",
  },
  colorDot: {
    height: "25px",
    width: "25px",
    borderRadius: "50%",
    display: "inline-block",
    marginRight: theme.spacing(1),
  }
}));

const Student = ({ data, students, setStudents }) => {
  const classes = useStyles();
  const [name, setName] = useState(data.name);
  const [color, setColor] = useState(data.color);
  const [showEditStudentModal, setShowEditStudentModal] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [studentEmail, setStudentEmail] = useState(data.email);
  const [allowLogin, setAllowLogin] = useState(data.allowLogin);
  const location = useLocation();
  const { country } = useContext(UserContext);

  const submitUpdatedStudent = (event) => {
    event.preventDefault();
    const id = data._id;
    const submitData = {
      _id: id,
      name: name,
      color: color,
      email: studentEmail,
      allowLogin: allowLogin,
    };
    
    updateStudent(submitData)
      .then((response) => {
        logAction("Updated student", country, location.pathname, submitData); //Mixpanel logging
        let studentIndex = students.findIndex((student) => student._id === id);
        let studentsCopy = [...students];
        studentsCopy[studentIndex].name = name;
        studentsCopy[studentIndex].color = color;
        studentsCopy[studentIndex].email = studentEmail;
        studentsCopy[studentIndex].allowLogin = allowLogin;
        setStudents([...studentsCopy]);
        document.getElementById("form").reset();
      })
      .catch((error) => {
        console.log("An error occured during student submission:", error);
      });
    setShowEditStudentModal(false);
  };

  const hideModal = (event) => {
    setName(data.name);
    setColor(data.color);
    setShowEditStudentModal(false);
    // TODO: do we need to set all attributes here or should we be clearing them?
    setStudentEmail(data.email);
    setAllowLogin(data.allowLogin);
  };

  const onDelete = (event) => {
    event.preventDefault();
    deleteStudent({ deleteId: data._id })
      .then((response) => {
        logAction("Deleted student", country, location.pathname, data); //Mixpanel logging
        setStudents(students.filter((st) => st._id !== data._id));
      })
      .catch((error) => {
        console.log("An error occurred trying to delete ", data.name, ": ", error);
      });
    setShowDeleteConfirm(false);
  };

  const deleteConfirmModal = () => {
    // TODO: Prevent delete of a student with a login?
    return (
      <Dialog open={showDeleteConfirm}>
        <DialogTitle>Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this student?</Typography>
        </DialogContent>
        <DialogActions>
          <StyledButton
            onClick={() => {
              setShowDeleteConfirm(false);
            }}
          >
            Cancel
          </StyledButton>
          <StyledButton onClick={onDelete}>OK</StyledButton>
        </DialogActions>
      </Dialog>
    );
  };

  const clickStudent = () => {
    setName(data.name);
    setColor(data.color);
    setShowEditStudentModal(true);
    setStudentEmail(data.email);
    setAllowLogin(data.allowLogin);
  };

  return (
    <Fragment>
      <ListItem
        className={classes.root}
        onClick={() => {clickStudent()}}
      >
        <span className={classes.colorDot} style={{ backgroundColor: data.color }}></span>
        {data.name}
        <ListItemSecondaryAction>
          <Tooltip title={data.allowLogin ? 'A student with "Allow Login" enabled cannot be deleted' : ""}>
            <span>
              <IconButton
                disabled={data.allowLogin}
                onClick={() => {
                  setShowDeleteConfirm(true);
                }}
              >
                <Clear />
              </IconButton>
            </span>
          </Tooltip>
        </ListItemSecondaryAction>
      </ListItem>
      {deleteConfirmModal()}
      <StudentModal isOpen={showEditStudentModal} cancelFunction={hideModal} submitFunction={submitUpdatedStudent} name={name} setName={setName} color={color} setColor={setColor} email={studentEmail} setEmail={setStudentEmail} allowLogin={allowLogin} setAllowLogin={setAllowLogin} />
    </Fragment>
  );
};

export default Student;