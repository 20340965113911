import React, { Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { Typography, Select, FormControl, InputLabel, MenuItem } from '@material-ui/core';
import { ToggleButton } from "@material-ui/lab";
import { ToggleButtonGroup } from "@material-ui/lab";

const useStyles = makeStyles(theme => ({
    fullWidth: {
        width: "100%",
    },
    optionsRow: {
        marginTop: theme.spacing(1),
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        [theme.breakpoints.down('xs')]: {
            marginBottom: theme.spacing(1),
            flexDirection: "column",
            alignItems: "flex-start",
        }
    },
    optionsRowLabel: {
        flexGrow: 1
    },
    toggleButton: {
        width: 100
    }
}));

const ProjectDuplicateOptionStudentGrouping = ({students, studentsToInclude, optionParticipation, setOptionParticipation, studentGroups, setStudentGroups}) => {

    const classes = useStyles();

    useEffect(() => {
        let studentGroupsTemp = [];
        switch (optionParticipation) {
            case "together":
                studentGroupsTemp.push(studentsToInclude);
                break;
            case "individually":
            case "groups":
                studentsToInclude.map((student) => studentGroupsTemp.push([student]));
                break;
            default:
                break;
        }
        setStudentGroups(studentGroupsTemp);
    }, [studentsToInclude, optionParticipation, setStudentGroups]);

    const handleParticipationChange = (event, newValue) => {
        if (newValue) {
            setOptionParticipation(newValue);
        }
    };

    const handleStudentGroupsChange = (event) => {
        const changedGroup = event.target.value
        const changedGroupIndex = Number(event.target.name.match(/Group\s*(\d+)/)[1]) - 1;
        let studentGroupsTemp = [...studentGroups];
        studentGroupsTemp[changedGroupIndex] = changedGroup;
        //loop through changedGroup and remove students from the other groups
        changedGroup.map((student) => {
            studentGroups.forEach((group, index) => {
                if (index !== changedGroupIndex) {
                    group.map((s, i) => {
                        if (student === s) {
                            studentGroupsTemp[index].splice(i, 1)
                        }
                        return true;
                    })
                }
            })
            return true;
        })
        setStudentGroups(studentGroupsTemp);
    };

    return (
        <Fragment>
            {
                studentsToInclude.length >= 2
                ? <div className={classes.optionsRow}>
                    <div className={classes.optionsRowLabel}>
                        <Typography>The students will be participating</Typography>
                    </div>
                    <ToggleButtonGroup 
                        exclusive
                        size={"small"}
                        value={optionParticipation} 
                        onChange={handleParticipationChange}
                        >
                        <ToggleButton className={classes.toggleButton} value={"together"}>Together</ToggleButton>
                        <ToggleButton className={classes.toggleButton} value={"individually"}>Individually</ToggleButton>
                        {
                            studentsToInclude.length > 2 
                            ? <ToggleButton className={classes.toggleButton} value={"groups"}>In Groups</ToggleButton>   
                            : null
                        }
                    </ToggleButtonGroup>
                </div>
                : null
            }
            {
                optionParticipation  === "groups"
                ? studentGroups.map((studentGroup, i) => {
                    const groupName = `Group ${i + 1}`
                    return (
                        <FormControl className={classes.fullWidth} key={i}>
                            <InputLabel>{groupName}</InputLabel>
                            <Select name={groupName} value={studentGroup} onChange={handleStudentGroupsChange} multiple>
                                {
                                    studentsToInclude.map((studentToInclude, s) => {
                                        let matchingStudent = students.filter((student) => student._id === studentToInclude)
                                        matchingStudent = matchingStudent[0]
                                        return <MenuItem key={s} value={matchingStudent._id}>{matchingStudent.name}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                    )
                })
                : null
            }
        </Fragment>
    );
};

export default ProjectDuplicateOptionStudentGrouping;