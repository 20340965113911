import React, { useState, useEffect, Fragment, useContext } from "react";
import { Typography } from '@material-ui/core';
import { Help } from '@material-ui/icons';
import { getAccountDetail, createStripePortal } from "../../services/account_services.js";
import { makeStyles } from "@material-ui/core";
import UserContext from "../../UserContext.js";
import SimpleDialog from "../common/SimpleDialog.js";
import { Alert } from "@material-ui/lab";
import { DateTime } from "luxon";
import { logAction } from "../../log.js";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  banner: {
    borderRadius: 0,
    cursor: "pointer",
  },
  helpIcon: {
    color: theme.palette.grey[800]
  }
}));

const Banner = ({setBannerClearance}) => {
  const classes = useStyles();
  const location = useLocation();
  const [showBanner, setShowBanner] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [accountDetail, setAccountDetail] = useState({});
  const bannerHeight = 44;
  const { country } = useContext(UserContext);
  const locale = country === "US" ? "en-us" : "en-au";

  useEffect(() => {
    getAccountDetail()
      .then((accountDetail) => {
        setAccountDetail(accountDetail);
      })
      .catch((error) => {
        setAccountDetail({});
        console.log("Error:", error);
      });
  }, []);

  useEffect(() => {
    if (accountDetail.subscriptionStatus === "trialing") {
      if (!accountDetail.hasPaymentMethod) {
        const trialExpiry = accountDetail.hasOwnProperty("trialExpiry") ? DateTime.fromISO(accountDetail.trialExpiry) : DateTime.local();
        const today = DateTime.local();
        const difference = Math.floor(trialExpiry.diff(today, "days").values.days);
        if (difference <= 7) {
          logAction("Viewed banner notification: Trialing", country, location.pathname); //Mixpanel logging
          const diffText = difference === 1 ? <span>1 day</span> : <span>{difference} days</span>
          setMessage(<span>Your trial will end in {diffText}</span>)
          setModalTitle("Trial Ending");
          setModalMessage(
            <div>
              <Typography paragraph>Your trial will end in {diffText}</Typography>
              <Typography paragraph>To ensure continued access, click <strong>Manage Subscription</strong> then <strong>Add payment method</strong>.</Typography>
            </div>
          );
          setBannerClearance(bannerHeight);
          setShowBanner(true);
        };
      };
    } else if (accountDetail.subscriptionStatus === "past_due") {
      logAction("Viewed banner notification: Past due", country, location.pathname); //Mixpanel logging
      const nextPaymentAttempt = accountDetail.hasOwnProperty("nextPaymentAttempt") ? DateTime.fromISO(accountDetail.nextPaymentAttempt).toLocaleString({ month: "short", day: "numeric", locale: locale }) : null;
      setMessage(<span>Your last payment was unsuccessful</span>)
      setModalTitle("Payment Unsuccessful");
      setModalMessage(
        <div>
          <Typography paragraph>Your last payment was unsuccessful and your account may soon become inactive.</Typography>
          <Typography paragraph>To ensure continued access, please check your balance or click <strong>Manage Subscription</strong> to change your payment method.</Typography>
          <Typography paragraph>We will try to take payment again on <strong>{nextPaymentAttempt}</strong>.</Typography>
        </div>
      );
      setBannerClearance(bannerHeight);
      setShowBanner(true);
    } else if (accountDetail.cancelAtPeriodEnd) {
      logAction("Viewed banner notification: Cancel at period end", country, location.pathname); //Mixpanel logging
      const subscriptionExpiry = accountDetail.hasOwnProperty("subscriptionExpiry") ? DateTime.fromISO(accountDetail.subscriptionExpiry).toLocaleString({ month: "short", day: "numeric", locale: locale }) : null;
      setMessage(<span>Your subscription will expire on {subscriptionExpiry}</span>)
      setModalTitle("Subscription Status");
      setModalMessage(
        <div>
          <Typography paragraph>Your subscription has been cancelled and will expire on <strong>{subscriptionExpiry}</strong>.</Typography>
          <Typography paragraph>To reactivate, click <strong>Manage Subscription</strong> then <strong>Renew plan</strong>.</Typography>
        </div>
      );
      setBannerClearance(bannerHeight);
      setShowBanner(true);
    }
  }, [accountDetail, country, locale, location, setBannerClearance]);

  const openModal = () => {
    logAction("Clicked banner notification", country, location.pathname); //Mixpanel logging
    setShowModal(true);
  };

  const hideModal = () => {
    setShowModal(false);
  };

  const openStripePortal = () => {
    logAction("Clicked Manage Subscription", country, location.pathname); //Mixpanel logging
    createStripePortal();
  }

  return (
    showBanner && <Fragment>
      <Alert 
        severity="error" 
        className={classes.banner}
        onClick={() => openModal()}
        action={<Help className={classes.helpIcon} />}
      >
        {message}
      </Alert>
      <SimpleDialog 
        open={showModal}
        onClose={hideModal}
        title={modalTitle}
        buttonPrimary={{
          onClick: () => {openStripePortal()},
          label: "Manage Subscription"
        }}
        buttonSecondary={{
          onClick: () => {hideModal()},
          label: "Cancel"
        }}
      >
        {modalMessage}
      </SimpleDialog>
    </Fragment>
  )
}

export default Banner;