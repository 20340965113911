import React from "react";

// set defaults for the context
const UserContext = React.createContext({
    pixelRatio: 0, 
    setPixelRatio: () => {},
    firstName: '',
    setFirstName: () => {},
    lastName: '',
    setLastName: () => {},
    username: '',
    setUsername: () => {},
    email: '',
    setEmail: () => {},
    country: '',
    setCountry: () => {},
    emailVerified: false, 
    setEmailVerified: () => {},
    showTutorial: false, 
    setShowTutorial: () => {},
    hasAcceptedLatestTerms: false,
    setHasAcceptedLatestTerms: () => {},
    dateJoined: '',
    setDateJoined: () => {},
    studentId: "",
    setStudentId: () => {},
    userPermissions: {},
    setUserPermissions: () => {},
    debugMode: false,
    setDebugMode: () => {},
    primaryFirstName: "",
    setPrimaryFirstName: () => {},
});

export default UserContext;