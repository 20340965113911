import { DateTime } from "luxon";

function getFilteredData( activities, criteria ) {
    //criteria.completed - 0 = false, 1 = true, 2 = both
    //criteria.date.mode - 0 = dynamic, 1 = static, 2 = no date, 3 = all
    //criteria.date.from
    //criteria.date.to
    //criteria.student
    //criteria.category
    const today = DateTime.local().startOf('day');
    let data = activities.filter(activity => {
        let date = DateTime.fromISO(activity.date);
        let dateCompleted = DateTime.fromISO(activity.dateCompleted);
        if (criteria.completed === 1) {
            switch (criteria.date.mode) {
                case 0: 
                    return activity.dateCompleted && 
                    dateCompleted >= today.plus({days: criteria.date.from}) &&
                    dateCompleted <= today.plus({days: criteria.date.to});
                case 1: 
                    dateCompleted = DateTime.fromISO(activity.dateCompleted).toUTC().toString()
                    return activity.dateCompleted && 
                    dateCompleted >= criteria.date.from &&
                    dateCompleted <= DateTime.fromISO(criteria.date.to).endOf('day').toUTC().toString();
                case 2: 
                    return activity.dateCompleted;
                default:
                    return null;
            }
        } else {
            switch (criteria.date.mode) {
                case 0: 
                    return (
                        date >= today.plus({days: criteria.date.from}) &&
                        date <= today.plus({days: criteria.date.to}) &&
                        !activity.dateCompleted
                    );
                case 1: 
                    date = DateTime.fromISO(activity.date).toUTC().toString();
                    if (criteria.completed === 0) { // if completed is 0, only show completed, otherwise show all
                        return (
                            date >= criteria.date.from &&
                            date <= DateTime.fromISO(criteria.date.to).endOf('day').toUTC().toString() &&
                            !activity.dateCompleted
                        )
                    } else {
                        return (
                            date >= criteria.date.from &&
                            date <= DateTime.fromISO(criteria.date.to).endOf('day').toUTC().toString()
                        )
                    }
                case 2:
                    return (
                        !activity.date &&
                        !activity.dateCompleted
                    );
                case 3:
                    return (
                        !activity.dateCompleted
                    );
                default: 
                    return null;
            }
        }
    });

    //Apply student filtering
    if (criteria.student.length > 0 && criteria.student !== 0) {
        data = data.filter(activity => {
            let studentMatch = false;
            activity.students.forEach(s => {
                if (s === criteria.student) {
                    studentMatch = true;
                }
            });
            return studentMatch;
        })
    }

    //Apply category filtering
     if (criteria.category) {
        if (criteria.category === "none") {
            data = data.filter(activity => {
                if (activity.categories.length === 0) {
                    return true
                } else {
                    return false
                }
            })
        } else {
            data = data.filter(activity => {
                let categoryMatch = false;
                activity.categories.forEach(c => {
                    if (c === criteria.category._id) {
                        categoryMatch = true;
                    }
                });
                return categoryMatch;
            })
        }
    }

    //Apply project filtering
    if (criteria.project) {
        data = data.filter(activity => {
            if (activity.project === criteria.project) {
                return activity;
            } else {
                return false;
            }
        })
    }

    return data;
}

function groupByDate (filteredActivities, sortMethod, locale) {
    let groupedActivities = [];
    function reduceFunc(groupedActivities, activity) {
        let date = null;
        let dateLabel = "";
        switch (sortMethod) {
            case "date":
                date = DateTime.fromISO(activity.date);
                date.isValid ? dateLabel = date.toLocaleString({ weekday: 'long', month: 'long', day: '2-digit', locale: locale }) : dateLabel = "Unscheduled";
                break;
            case "dateCompleted":
            case "dateCompletedDescending":
                date = DateTime.fromISO(activity.dateCompleted);
                date.isValid ? dateLabel = date.toLocaleString({ weekday: 'long', month: 'long', day: '2-digit', locale: locale }) : dateLabel = "Incomplete"; //This should never happen, but just in case
                break;
            default:
                date = DateTime.fromISO(activity.date);
                date.isValid ? dateLabel = date.toLocaleString({ weekday: 'long', month: 'long', day: '2-digit', locale: locale }) : dateLabel = "Unscheduled";
        }
        let index = groupedActivities.findIndex((group) => group.dateLabel === dateLabel);
        if (index < 0) {
            const length = groupedActivities.push({
                date: date,
                dateLabel: dateLabel,
                activities: []
            });
            index = length - 1;
        };
        groupedActivities[index].activities.push(activity);
        return groupedActivities;
    }

    return filteredActivities.reduce(reduceFunc, groupedActivities)

}

function groupByDateInRange (filteredActivities, calendarDays) {
    let returnData = [];
    calendarDays.map((date) => {
        returnData.push({
            date: date,
            dateLabel: date.toString(),
            weekday: date.weekday,
            activities: filteredActivities.filter((activity) => {
                const activityDate = DateTime.fromISO(activity.date);
                if (date.toMillis() === activityDate.startOf('day').toMillis()) {
                    return activityDate;
                } else {
                    return false;
                }
            })
        })
        return null;
    })
    return returnData;
}

function applyTextFilter(activitiesByDate, filterText) {
    const returnData = [];
    activitiesByDate.forEach((group) => {
        group.activities.forEach((activity) => {
            if (activity.textContent.toUpperCase().includes(filterText.toUpperCase())) {
                let index = returnData.findIndex((returnGroup) => returnGroup.dateLabel === group.dateLabel);
                if (index < 0) {
                    const length = returnData.push({
                        date: group.date,
                        dateLabel: group.dateLabel,
                        activities: []
                    });
                    index = length - 1;
                };
                returnData[index].activities.push(activity);
            }
        })
    })
    return returnData;
}

export { getFilteredData, groupByDate, groupByDateInRange, applyTextFilter };