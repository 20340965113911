import React, {useContext} from 'react';
import { Typography, IconButton } from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core';
import { useMediaQuery } from '@material-ui/core';
import StudentFilter from '../../components/common/StudentFilter.js';
import { ChevronLeft } from '@material-ui/icons';
import { ChevronRight } from '@material-ui/icons';
import RoutineConfigButton from './RoutineConfigButton.js';
import UserContext from '../../UserContext.js';

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.common.white,
        alignItems: "center",
        zIndex: 2,
        paddingTop: theme.spacing(1),
        position: "fixed", 
        top: 0,
    },
    toolbar: theme.mixins.toolbar,
    weekNav: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    title: {
        display: "flex",
        justifyContent: "center",
        width: theme.spacing(15)
    },
    subtitle: {
        color: theme.palette.grey[800],
        paddingBottom: theme.spacing(1)
    }
}));

const CalendarHeader = ({ students, categories, routineItems, setRoutineItems, filterIndex, setFilterIndex, selectedStudent, setSelectedStudent, dateRange, navOpen, drawerWidth, bannerClearance }) => {

    const classes = useStyles();
    const theme = useTheme();
    const screenIsMd = useMediaQuery(theme.breakpoints.down('md')); 
    const { userPermissions } = useContext(UserContext);

    let headerWidth = "100vw";
    if (navOpen && !screenIsMd) {
        headerWidth = "calc(100vw - " + drawerWidth + "px)"
    };

    const navBack = () => {
        setFilterIndex(filterIndex - 1)
    };

    const navForward = () => {
        setFilterIndex(filterIndex + 1)
    };

    const dateRangeLabels = dateRange.map((week, i) => {
        let title = ""
        switch (true) {
            case (i <= 50):
                title = "Past";
                break;
            case (i === 51): 
                title = "Last Week";
                break;
            case (i === 52):
                title = "This Week";
                break;
            case (i === 53):
                title = "Next Week";
                break;
            case (i >= 54):
                title = "Future";
                break;
            default:
                break;
        }
        return [
            title,
            week[0].month === week[1].month ? week[0].day : `${week[0].day} ${week[0].toLocaleString({month: "short"})}`,
            `${week[1].day} ${week[1].toLocaleString({month: "short"})}`,
        ]
    })

    return (
        <div className={classes.root} style={{width: headerWidth, top: bannerClearance}}>
            <div className={classes.toolbar} />
            { userPermissions.manageRoutine &&
                <RoutineConfigButton 
                    students={students}
                    categories={categories}
                    routineItems={routineItems}
                    setRoutineItems={setRoutineItems}
                    bannerClearance={bannerClearance}
                />
            }
            <div className={classes.weekNav}>
                <IconButton
                    disabled={filterIndex === 0}
                    onClick={() => {navBack()}}
                    size="small"
                >
                    <ChevronLeft />
                </IconButton>
                <Typography variant="h5" className={classes.title}>
                    {dateRangeLabels[filterIndex][0]}
                </Typography>
                <IconButton
                    disabled={filterIndex === dateRange.length - 1}
                    onClick={() => {navForward()}}
                    size="small"
                >
                    <ChevronRight />
                </IconButton>
            </div>
            <Typography variant="subtitle2" className={classes.subtitle}>
                {`${dateRangeLabels[filterIndex][1]} - ${dateRangeLabels[filterIndex][2]}`}
            </Typography>
            { userPermissions.filterStudents &&
                <StudentFilter 
                    students={students} 
                    selectedStudent={selectedStudent} 
                    setSelectedStudent={setSelectedStudent} 
                />
            }
        </div>
    )
}

export default CalendarHeader;