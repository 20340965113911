import React, { useEffect, useContext } from 'react';
import NewActivityButton from '../../components/activities/NewActivityButton.js';
import Calendar from '../../components/calendar/Calendar.js';
import { getAllActivities } from '../../services/activity_services.js';
import PageBase from '../../components/layout/PageBase.js';
import UserContext from '../../UserContext.js';

const MyWeek = ({ activities, setActivities, students, categories, projects, navOpen, drawerWidth, bannerClearance, setNotificationDetails, routineItems, setRoutineItems }) => {

    const { userPermissions } = useContext(UserContext);

    useEffect(() => {
        getAllActivities()
            .then((response) => setActivities(response.data))
            .catch((error) => console.log(error))
    }, [setActivities]);

    return (
        <PageBase navOpen={navOpen} drawerWidth={drawerWidth}>
            <Calendar 
                activities={activities} 
                setActivities={setActivities} 
                students={students} 
                categories={categories} 
                projects={projects}
                setNotificationDetails={setNotificationDetails}
                routineItems={routineItems}
                setRoutineItems={setRoutineItems}
                navOpen={navOpen}
                drawerWidth={drawerWidth}
                bannerClearance={bannerClearance}
            />
            { userPermissions.addActivity &&
                <NewActivityButton 
                    setActivities={setActivities}
                    activities={activities}
                    students={students}
                    categories={categories} 
                    projects={projects}
                    setNotificationDetails={setNotificationDetails}
                />
            }
        </PageBase>
    )
}

export default MyWeek;