import React from 'react';
import { useMediaQuery, Tabs, Tab, Chip } from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    statusTab: {
        display: "flex",
        flexWrap: "noWrap",
    },
    statusChip: {
        marginLeft: theme.spacing(1),
    }
}));

const StatusTabs = ({ activeTab, setActiveTab, filterIndex, filterCounts, tabLabels }) => {

    const classes = useStyles();
    const theme = useTheme();
    const screenIsXs = useMediaQuery(theme.breakpoints.down('xs')); 
    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <Tabs
            value={activeTab}
            onChange={handleTabChange}
            variant={screenIsXs ? "scrollable": "fullWidth"}
            indicatorColor="primary"
            textColor="primary"
        >
            {tabLabels.map((label, i) => {
                return (
                    <Tab 
                        key={i}
                        label={
                            <div className={classes.statusTab}>
                                {label}
                                <Chip 
                                    className={classes.statusChip}
                                    size="small"
                                    label={filterCounts.length ? filterCounts[i][filterIndex] : ""}
                                />
                            </div>
                        } />
                )
            })}
        </Tabs>
    )
}

export default StatusTabs;