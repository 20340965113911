import React, { Fragment, useContext, useState, useEffect, useCallback } from "react";
import { useTheme, makeStyles } from "@material-ui/core";
import { MenuItem, useMediaQuery, Dialog, DialogActions, DialogContent, InputLabel, DialogTitle, IconButton, Menu, ButtonGroup, Button, Typography } from "@material-ui/core";
import { ArrowBack, MoreVert, Done, MoreHoriz, Send, HourglassEmpty } from "@material-ui/icons";
import StyledButton from "../common/StyledButton.js";
import { Alert } from "@material-ui/lab";
import DatePicker from "../common/DatePicker.js";
import Attachments from "../common/Attachments.js";
import StudentSelector from "../common/StudentSelector.js";
import CategorySelector from "../common/CategorySelector.js";
import ProjectSelector from "../common/ProjectSelector.js";
import Confetti from "../common/Confetti.js";
import ActivityContext from "../../containers/activities/ActivityContext.js";
import submitActivity from "../../containers/activities/SubmitActivity.js";
import { Editor } from "@tinymce/tinymce-react";
import { DateTime } from "luxon";
import UserContext from "../../UserContext.js";
import DuplicateModal from "./DuplicateModal.js";
import AttachmentLinkModal from "./AttachmentLinkModal.js";
import AttachmentLinks from "./AttachmentLinks.js";
import Comments from "./Comments.js";
import Spinner from "../common/Spinner.js";
import { useTreatments, SplitContext } from "@splitsoftware/splitio-react";
import { logAction } from "../../log.js";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  titleText: {
    flexGrow: 1,
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  fullWidth: {
    width: "100%",
  },
  label: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  completedButtonRootOverride: {
    '&:disabled': {
      opacity: 1,
      backgroundColor: theme.palette.common.white,
      borderColor: theme.palette.common.white,
      color: theme.palette.complete.main,
    },
  },
  completedButton: {
    backgroundColor: theme.palette.complete.main,
    borderColor: theme.palette.complete.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.complete.shade[700],
      borderColor: theme.palette.complete.shade[700],
    },
  },
  waitingForReviewButtonRoot: {
    '&:disabled': {
      opacity: 1,
      backgroundColor: theme.palette.common.white,
      borderColor: theme.palette.common.white,
      color: theme.palette.warning.main,
    },
  },
  waitingForReviewButton: {
    backgroundColor: theme.palette.warning.main,
    borderColor: theme.palette.warning.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.warning.shade[900],
      borderColor: theme.palette.warning.shade[900],
    },
  }
}));

export default function ActivityModal({ activities, setActivities, isOpen, deleteFunction, students, categories, projects, location, hideModal = null, projectId = '', pdfDetails = {}, projectStudents = [], projectCategories = [], setNotificationDetails }) {
  const classes = useStyles();
  const theme = useTheme();
  const { country, userPermissions, userId, studentId } = useContext(UserContext);
  const [isFormValid, setIsFormValid] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showDuplicate, setShowDuplicate] = useState(false);
  const [showAttachmentLinkModal, setShowAttachmentLinkModal] = useState(false);
  const [showCompletedDate, setShowCompletedDate] = useState(false);
  const [pendingSave, setPendingSave] = useState(false);
  const [closingModal, setClosingModal] = useState(false);
  const [saveError, setSaveError] = useState(false);
  const screenIsXs = useMediaQuery(theme.breakpoints.down("xs"));
  const saveInterval = 1000;
  const { activityId, setActivityId, details, setDetails, studentsToInclude, setStudentsToInclude, categoriesToInclude, setCategoriesToInclude, projectToInclude, setProjectToInclude, activityDate, setActivityDate, activitySentForReviewDate, setActivitySentForReviewDate, activityCompleteDate, setActivityCompleteDate, attachments, setAttachments, attachmentLinks, setAttachmentLinks, comments, setComments } = useContext(ActivityContext);
  const [fire, setFire] = useState(false);
  const [clickCoords, setClickCoords] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [commentText, setCommentText] = useState('');
  const { isReady } = useContext(SplitContext);
  const treatmentName = {
    studentLogin: "student_login"
  };
  const treatment = useTreatments([treatmentName.studentLogin]);

  const saveActivity = useCallback((closeOnSave=false, commentsTemp = comments) => {
    submitActivity({
      activities, 
      setActivities, 
      activityId, 
      setActivityId, 
      details, 
      studentsToInclude, 
      categoriesToInclude, 
      projectToInclude, 
      activityDate, 
      activitySentForReviewDate,
      activityCompleteDate, 
      attachments, 
      attachmentLinks, 
      commentsTemp,
      location, 
      country, 
      pendingSave, 
      setPendingSave, 
      setSaveError, 
      setClosingModal, 
      hideModal, 
      closeOnSave
    })
  }, 
  [
    activities, 
    setActivities, 
    activityId, 
    setActivityId, 
    details, 
    studentsToInclude, 
    categoriesToInclude, 
    projectToInclude, 
    activityDate,
    activitySentForReviewDate,
    activityCompleteDate, 
    attachments, 
    attachmentLinks, 
    comments,
    location, 
    country, 
    pendingSave, 
    setPendingSave, 
    setSaveError, 
    setClosingModal, 
    hideModal, 
  ]);

  useEffect(() => {
    if (pendingSave && closingModal) {
      setShowSpinner(true);
    } else {
      setShowSpinner(false);
    }
  }, [pendingSave, closingModal]);

  useEffect(() => {
    if (projectId) {
      setProjectToInclude(projectId);
      setStudentsToInclude(projectStudents);
      setCategoriesToInclude(projectCategories);
    };
  }, [projectId, projectStudents, projectCategories, setProjectToInclude, setStudentsToInclude, setCategoriesToInclude]);

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        if (details !== "") {
          //Auto save only if details field is not blank
          saveActivity();
        }
      }, saveInterval);
      return () => clearTimeout(timer);
    }
  }, [isOpen, details, studentsToInclude, categoriesToInclude, projectToInclude, activityDate, activitySentForReviewDate, activityCompleteDate, attachments, attachmentLinks, comments]);
  
  useEffect(() => {
    if (pdfDetails.hasOwnProperty('userFileName')) {
      setAttachmentLinks([{
        userFileName: pdfDetails.userFileName,
        s3FileName: pdfDetails.s3FileName,
        fileType: pdfDetails.fileType,
        pageNumber: pdfDetails.pageNumber,
        linkText: pdfDetails.linkText,
        type: pdfDetails.type
      }])
    }
  }, [pdfDetails, setAttachmentLinks]);
  
  const onSubmit = (e) => {
    if (details !== "") {
      let commentsTemp = comments;
      if (commentText) { //If there is an unsaved comment
        commentsTemp = addComment();
      }
      setClosingModal(true);
      setIsFormValid(true);
      saveActivity(true, commentsTemp); //Argument "true" tells submit function to close modal and update local state
    } else {
      if (activityId) {
        setIsFormValid(false);
      } else {
        setPendingSave(false);
        hideModal(); //If there are no details and nothing has been saved, do nothing
      }
    }
  };

  const onDelete = () => {
    setShowDeleteConfirm(false);
    deleteFunction();
  };

  const validationMessage = () => {
    if (!isFormValid) {
      return (
        <Alert severity="error" visibility="hidden">
          Details are required
        </Alert>
      );
    }
  };

  const saveErrorMessage = () => {
    let alertTime = DateTime.local().toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
    if (saveError) {
      return (
        <Alert severity="error" visibility="hidden">
          Oops... there was an error saving this activity. Please try again in a few minutes or <a href="mailto: contact@learnfreely.app">contact us</a> if the problem continues.<br />
          (Timestamp: {alertTime})
        </Alert>
      );
    }
  };

  const detailEntry = () => {
    const handleEditorChange = (content, editor) => {
      setDetails(content);
    };
    return (
      <Fragment>
        <InputLabel className={classes.label}>Details</InputLabel>
        <Editor
          tinymceScriptSrc='/tinymce/tinymce.min.js'
          value={details}
          onEditorChange={handleEditorChange}
          init={{
            editable_root: userPermissions.activity.editDetails,
            license_key:'gpl',
            selector: "textarea",
            menubar: false,
            statusbar: false,
            contextmenu: false,
            plugins: "lists fullscreen autoresize autolink link",
            max_height: 300,
            toolbar: "bold italic underline bullist backcolor | print fullscreen | openlink | undo redo",
            toolbar_mode: "sliding",
            valid_elements: "p,br,ul,ol,li,strong/b,em/i,span[style],a[href|target=_blank]",
            valid_styles: {
              span: "text-decoration,background-color",
            },
            mobile: {
              toolbar: "bold italic underline bullist | print | openlink | undo redo",
            },
            custom_colors: false,
            color_map: [
              "#FFB3A7", "#FFB3A7", 
              "#F58F84", "#F58F84",
              "#BE90D4", "#BE90D4",
              "#89C4F4", "#89C4F4", 
              "#36D7B7", "#36D7B7", 
              "#87D37C", "#87D37C", 
              "#F5D76E", "#F5D76E", 
              "#FFB94E", "#FFB94E"
            ]
            /* templates: [
                            {title: 'Lesson plan', description: 'This is the lesson plan description', content: '<p>This is a paragraph</p><p>This is a list</p><ul><li>Item One</li><li>Item Two</li><li>Item Three</li></ul>'},
                            {title: 'Craft project', description: 'This is the craft project description', content: '<strong>this is some bold text</strong>'}
                        ] */
          }}
        />
      </Fragment>
    );
  };

  const activityMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const activityMenuClose = () => {
    setAnchorEl(null);
  };

  const deleteConfirmModal = () => {
    return (
      <Dialog open={showDeleteConfirm}>
        <DialogTitle>Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this activity?</Typography>
        </DialogContent>
        <DialogActions>
          <StyledButton
            onClick={() => {
              setShowDeleteConfirm(false);
            }}
          >
            Cancel
          </StyledButton>
          <StyledButton onClick={onDelete}>OK</StyledButton>
        </DialogActions>
      </Dialog>
    );
  };

  const completeButton = () => {
    let showButton = true;
    const readOnly = !userPermissions.completeActivity;
    if (!userPermissions.completeActivity && !activityCompleteDate) {
      showButton = false;
    }
    const button = {
      text: "Mark as Complete",
      icon: "",
      class: null,
    };
    if (activityCompleteDate) {
      button.text = "Completed ";
      button.icon = <Done />;
      button.class = classes.completedButton;
    }
    const toggleComplete = (e) => {
      if (activityCompleteDate) {
        setActivityCompleteDate(null);
      } else {
        setActivityCompleteDate(DateTime.local().toUTC().toString());
        setClickCoords([e.clientX, e.clientY]);
        setFire(true);
      }
    };

    const moreButton = () => {
      if (activityCompleteDate) {
        return (
          <Button
            className={button.class}
            onClick={() => {
              setShowCompletedDate(true);
            }}
          >
            <MoreHoriz fontSize="small" />
          </Button>
        );
      }
    };

    return (
      <Fragment>
        { showButton &&
          <ButtonGroup>
            <Button
              className={readOnly ? null : button.class}
              classes={readOnly ? {root: classes.completedButtonRootOverride} : null}
              startIcon={button.icon}
              disabled={activityId && !readOnly ? false : true}
              onClick={(e) => {
                toggleComplete(e);
              }}
            >
              {button.text}
            </Button>
            {userPermissions.completeActivity && moreButton()}
          </ButtonGroup>
        }
        {CompletedDateModal()}
      </Fragment>
    );
  };

  const sendForReviewButton = () => {
    let showButton = true;
    if (activityCompleteDate) {
      showButton = false;
    } else if (userPermissions.completeActivity && !activitySentForReviewDate) {
      showButton = false;
    }
    const readOnly = userPermissions.completeActivity;
    const button = {
      text: "Send for Review",
      icon: <Send />,
      class: null,
    };
    if (activitySentForReviewDate) {
      button.text = "Waiting for Review";
      button.icon = <HourglassEmpty />;
      button.class = classes.waitingForReviewButton;
    };
    const toggleSendForReview = () => {
      if (activitySentForReviewDate) {
        setActivitySentForReviewDate(null);
      } else {
        setActivitySentForReviewDate(DateTime.local().toUTC().toString());
      }
    };

    return (
      showButton &&
      <StyledButton
        startIcon={button.icon}
        disabled={activityId && !readOnly ? false : true}
        className={readOnly ? null : button.class}
        classes={readOnly ? {root: classes.waitingForReviewButtonRoot} : null}
        onClick={() => {toggleSendForReview()}}
      >
        {button.text}
      </StyledButton>
    );
  };

  const CompletedDateModal = () => {
    return (
      <Dialog open={showCompletedDate}>
        <DialogTitle>Completed Date</DialogTitle>
        <DialogContent>
          <DatePicker country={country} controlName={"activityCompleteDate"} controlValue={activityCompleteDate} setFunction={setActivityCompleteDate} />
        </DialogContent>
        <DialogActions>
          <StyledButton
            onClick={() => {
              setShowCompletedDate(false);
            }}
          >
            Done
          </StyledButton>
        </DialogActions>
      </Dialog>
    );
  };

  const showAttachments = () => {
    return (
      <Attachments attachments={attachments} setAttachments={setAttachments} location={location} setShowSpinner={setShowSpinner} showDropzone={userPermissions.activity.addAttachment} showDelete={userPermissions.activity.deleteAttachment} />
    );
  };

  const addComment = () => {
      const newComment = {
        userId: userId,
        studentId: studentId,
        commentType: "",
        dateCreated: DateTime.local().toUTC(),
        text: commentText
      }
      const commentsTemp = [...comments, newComment];
      setComments(commentsTemp)
      setCommentText('');
      logAction("Added activity comment", country, location.pathname, newComment); //Mixpanel logging 
      return commentsTemp;
  }

  return (
    <Dialog fullScreen={screenIsXs ? true : false} fullWidth={true} maxWidth={"md"} open={isOpen}>
      <DialogTitle>
        <div className={classes.dialogTitle}>
          <IconButton
            onClick={() => {
              onSubmit();
            }}
          >
            <ArrowBack />
          </IconButton>
          <div className={classes.titleText}>Activity</div>
          { (userPermissions.activity.editAttachmentLinks || userPermissions.addActivity || userPermissions.deleteActivity) &&
            <IconButton onClick={activityMenuOpen}>
              <MoreVert />
            </IconButton>
          }
          <Menu id="activity-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClick={activityMenuClose} onClose={activityMenuClose}>
            { userPermissions.activity.editAttachmentLinks && projectToInclude && 
              <MenuItem
                onClick={() => {
                  setShowAttachmentLinkModal(true);
                }}
              >
                Add attachment link
              </MenuItem>
            }
            { userPermissions.addActivity &&
              <MenuItem
                disabled={activityId ? false : true}
                onClick={() => {
                  setShowDuplicate(true);
                }}
              >
                Duplicate
              </MenuItem>
            }
            { userPermissions.deleteActivity &&
              <MenuItem
                disabled={activityId ? false : true}
                onClick={() => {
                  setShowDeleteConfirm(true);
                }}
              >
                Delete
              </MenuItem>
            }
          </Menu>
          {deleteConfirmModal()}
          <DuplicateModal activities={activities} setActivities={setActivities} students={students} showDuplicate={showDuplicate} setShowDuplicate={setShowDuplicate} setNotificationDetails={setNotificationDetails} />
          {projectToInclude && <AttachmentLinkModal isOpen={showAttachmentLinkModal} setShowAttachmentLinkModal={setShowAttachmentLinkModal} projects={projects} projectToInclude={projectToInclude} attachmentLinks={attachmentLinks} setAttachmentLinks={setAttachmentLinks} />}
        </div>
      </DialogTitle>
      <DialogContent>
        <form className={classes.form} id="activity_form" autoComplete="off">
          {validationMessage()}
          {saveErrorMessage()}
          <DatePicker country={country} controlName={"activityDate"} controlValue={activityDate} setFunction={setActivityDate} disabled={!userPermissions.activity.editDate} />
          <StudentSelector students={students} studentsToInclude={studentsToInclude} setStudentsToInclude={setStudentsToInclude} disabled={!userPermissions.activity.editStudent} />
          <CategorySelector categories={categories} categoriesToInclude={categoriesToInclude} setCategoriesToInclude={setCategoriesToInclude} disabled={!userPermissions.activity.editCategory} />
          <ProjectSelector projects={projects} projectToInclude={projectToInclude} setProjectToInclude={setProjectToInclude} disabled={!userPermissions.activity.editProject} />
          <AttachmentLinks attachmentLinks={attachmentLinks} setAttachmentLinks={setAttachmentLinks} location={location} showDelete={userPermissions.activity.editAttachmentLinks} />
          {detailEntry()}
          {
            activityId 
            ? <Fragment>
                {showAttachments()}
                {
                  isReady && treatment[treatmentName.studentLogin].treatment === "on" //Feature flag
                  ? <Comments comments={comments} setComments={setComments} commentText={commentText} setCommentText={setCommentText} submitFunction={addComment} students={students} location={location} />
                  : null
                }
            </Fragment>
            : null
          }
        </form>
      </DialogContent>
      <DialogActions>
        {sendForReviewButton()}
        {completeButton()}
        <StyledButton form="activity_form" onClick={() => {onSubmit()}}>
          Close
        </StyledButton>
      </DialogActions>
      <Spinner open={showSpinner} />
      <Confetti fire={fire} setFire={setFire} clickCoords={clickCoords} />
    </Dialog>
  );
}
