import React, { useState } from 'react';
import { useTheme, makeStyles } from '@material-ui/core';
import { TextField, Dialog, DialogTitle, DialogContent, DialogActions, InputLabel, IconButton, useMediaQuery } from '@material-ui/core';
import StyledButton from '../common/StyledButton.js';
import IconModal from '../common/IconModal.js';
import { CirclePicker } from 'react-color';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles(theme => ({
    fullWidthField: {
        display: "flex",
        width: "100%",
    },
    label: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(2),
    },
    iconButton: {
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: theme.palette.grey[600],
    }
}));

const CategoryModal = ({ isOpen, cancelFunction, submitFunction, name, setName, color, setColor, icon, setIcon }) => {

    const classes = useStyles();
    const theme = useTheme();
    const [showIconModal, setShowIconModal] = useState(false);
    const screenIsXs = useMediaQuery(theme.breakpoints.down("xs"));

    const onNameChange = (event) => {
        setName(event.target.value);
    }

    const onColorChange = (color) => {
        setColor(color.hex);
    }

    const onClickIcon = (clickedIcon) => {
        setIcon(clickedIcon);
        setShowIconModal(false);
    }

    const iconList = [
        "award",
        "atom",
        "balance-scale",
        "basketball-ball",
        "bell",
        "bible",
        "bicycle",
        "binoculars",
        "birthday-cake",
        "bolt",
        "bone",
        "book",
        "book-reader",
        "building",
        "bus",
        "calculator",
        "camera",
        "campground",
        "candy-cane",
        "carrot",
        "cash-register",
        "chart-line",
        "chart-pie",
        "chess",
        "church",
        "clipboard-list",
        "clock",
        "cloud-sun",
        "coffee",
        "cogs",
        "comments",
        "compass",
        "dice",
        "dna",
        "dog",
        "dollar-sign",
        "dove",
        "dragon",
        "exclamation",
        "film",
        "flask",
        "football-ball",
        "futbol",
        "gamepad",
        "gavel",
        "gift",
        "globe-africa",
        "guitar",
        "hamburger",
        "hammer",
        "handshake",
        "hat-wizard",
        "hiking",
        "horse",
        "hospital",
        "landmark",
        "laptop",
        "leaf",
        "lightbulb",
        "list-alt",
        "mask",
        "microphone",
        "mountain",
        "music",
        "palette",
        "pencil-alt",
        "piggy-bank",
        "plane",
        "puzzle-piece",
        "question",
        "robot",
        "rocket",
        "ruler",
        "search",
        "space-shuttle",
        "stethoscope",
        "store",
        "swimmer",
        "table-tennis",
        "thermometer-three-quarters",
        "tractor",
        "trophy",
        "utensils",
        "user-graduate",
        "video"
    ]

    const colorList = [ //Reference: http://bootflat.github.io/index.html
        "#F58F84",
        "#F47983",
        "#DB5A6B",
        "#A87CA0",
        "#BE90D4",
        "#4D8FAC",
        "#89C4F4",
        "#2ABB9B",
        "#87D37C",
        "#F5D76E",
        "#FFB94E",
        "#ABB7B7",
    ]

    return (
        <Dialog open={isOpen} fullScreen={screenIsXs ? true : false}>
            <DialogTitle>Category</DialogTitle>
            <DialogContent>
                <form id="form" onSubmit={submitFunction} autoComplete="off">
                    <TextField className={classes.fullWidthField} name="name" label="Name" value={name} onChange={onNameChange} required/>
                    <InputLabel className={classes.label}>Icon</InputLabel>
                    <IconButton className={classes.iconButton} onClick={() => {setShowIconModal(true)}}>
                        <FontAwesomeIcon 
                            icon={icon} 
                            style={{ color: color, width: theme.spacing(3) }}
                        />
                    </IconButton>
                    <InputLabel className={classes.label}>Colour</InputLabel>
                    <CirclePicker color={color} colors={colorList} onChangeComplete={onColorChange}/>
                </form>
            </DialogContent>
            <DialogActions>
                <StyledButton onClick={cancelFunction}>
                    Cancel
                </StyledButton>
                <StyledButton form="form" type="submit">
                    Done
                </StyledButton>
            </DialogActions>
            <IconModal 
                showIconModal={showIconModal} 
                setShowIconModal={setShowIconModal}
                iconList={iconList}
                onClickIcon={onClickIcon}
                iconType='FontAwesome'
            />
        </Dialog>
    )
}

export default CategoryModal;