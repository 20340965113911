import React, { useEffect, Fragment } from 'react';
import { Typography, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import CategorySelectorSingle from '../../common/CategorySelectorSingle.js';
import { Alert } from "@material-ui/lab";

const CategoryMapping = ({categories, projectPlans, selectedProjectPlan, categoryMap, setCategoryMap, showDuplicateCategoryMessage}) => {

    const projectPlanDetails = projectPlans.filter((projectPlan) => projectPlan._id === selectedProjectPlan);
    const defaultCategories = projectPlanDetails[0].defaultCategories;

    useEffect(() => {
        if (!categoryMap.length) {
            const categoryMapTemp = defaultCategories.map((category) => {
                return {
                    defaultCategory: category,
                    userCategory: {
                        id: "",
                        name: ""
                    }
                }
            })
            setCategoryMap(categoryMapTemp)
        };
    }, [categoryMap, setCategoryMap, defaultCategories]);

    const categoriesList = () => {
        return defaultCategories.map((defaultCategory, i) => {
            return (
                <TableRow key={i}>
                    <TableCell>{defaultCategory.name}</TableCell>
                    <TableCell>
                        <CategorySelectorSingle categories={categories} categoryMap={categoryMap} setCategoryMap={setCategoryMap} index={i} />
                    </TableCell>
                </TableRow>
            )
        })
    }

    return (
        <Fragment>
            {showDuplicateCategoryMessage ? <Alert severity="error">Please select a unique category for each row</Alert> : null}
            <Typography>For each subject covered in this project, choose the most suitable match from your existing categories</Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            Subjects covered in this project
                        </TableCell>
                        <TableCell>
                            Your categories
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {categoryMap.length ? categoriesList() : null}
                </TableBody>
            </Table>
        </Fragment>
    )
}

export default CategoryMapping;