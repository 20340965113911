import { getAllActivities } from "./activity_services.js";
import { getStudents } from "./student_services.js";
import { getCategories } from "./category_services.js";
import { getProjects } from "./project_services.js";
import { getAllGoals } from "./goals_services.js";
import { getRoutineItems } from "./routine_services.js";

export const refreshActivities = (setActivities) => {
  getAllActivities()
    .then((response) => {
      setActivities(response.data);
    })
    .catch((error) => console.log(error));
};

export const refreshStudents = (setStudents) => {
  getStudents()
    .then((response) => {
      setStudents(response.data);
    })
    .catch((error) => console.log(error));
};

export const refreshCategories = (setCategories) => {
  getCategories()
    .then((response) => {
      setCategories(
        response.data.sort((a, b) => {
          // Sort categories by name
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        })
      );
    })
    .catch((error) => console.log(error));
};

export const refreshProjects = (setProjects) => {
  getProjects()
    .then((response) => {
      setProjects(
        response.data.sort((a, b) => {
          // Sort projects by name
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        })
      );
    })
    .catch((error) => console.log(error));
};

export const refreshGoals = (setGoals) => {
  getAllGoals()
    .then((response) => {
      setGoals(response.data);
    })
    .catch((error) => console.log(error));
};

export const refreshRoutineItems = (setRoutineItems) => {
  getRoutineItems()
    .then((response) => {
      setRoutineItems(response.data)
    })
    .catch((error) => console.log(error));
};
