import React, { useContext, useRef } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core';
import StyledButton from "../common/StyledButton.js";
import { makeStyles } from "@material-ui/core";
import UserContext from "../../UserContext.js";
import { updateShowTutorial } from "../../services/user_services.js";
import { logAction } from "../../log.js";
import { useLocation } from "react-router-dom";
import VideoJS from "../common/VideoJS.js";

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    flexDirection: "column"
  },
  actions: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: "column",
    }
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      width: "100%",
      marginTop: theme.spacing(1),
      '&:not(:first-child)': {
        marginLeft: 0,
      }
    }
  }
}));

const Tutorial = ({showModalTutorial, setShowModalTutorial}) => {
  const classes = useStyles();
  const location = useLocation();
  const { country, setShowTutorial } = useContext(UserContext);
  const playerRef = useRef(null);

  const videoJsOptions = {
    autoplay: 'any',
    controls: true,
    fluid: true,
    sources: [{
      src: 'https://video.wixstatic.com/video/b5c998_4f9c77229fff42fea63446032bc4bc40/1080p/mp4/file.mp4',
      type: 'video/mp4'
    }]
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;
    player.on('play', () => {
      if (player.currentTime() === 0) {
        logAction("Introduction video started", country, location.pathname);
      }
    });
  };

  const getVideoProgress = () => {
    if (playerRef.current) {
      const loaded = playerRef.current.bufferedPercent();
      const playedSeconds = playerRef.current.currentTime();
      const durationSeconds = playerRef.current.duration();
      const played = playedSeconds / durationSeconds;
      return {
        loaded: loaded,
        played: played,
        playedSeconds: playedSeconds
      };
    }
  }

  const postponeTutorial = () => {
    logAction("Postponed tutorial", country, location.pathname, getVideoProgress()); //Mixpanel logging
    setShowModalTutorial(false);
  }

  const startTutorial = () => {
    window.open('https://www.learnfreely.app/onboarding-gettingstarted', '_blank');
    logAction("Started tutorial", country, location.pathname, getVideoProgress()); //Mixpanel logging
    updateShowTutorial({value: false});
    setShowTutorial(false);
    setShowModalTutorial(false);
  }

  return (
    <Dialog open={showModalTutorial} fullWidth={true} maxWidth={"sm"}>
      <DialogTitle>
        Welcome!
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography paragraph>
          Tiff explains how to get started with Freely
        </Typography>
        <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
      </DialogContent>
      <DialogActions className={classes.actions}>
        <StyledButton className={classes.button} onClick={() => postponeTutorial()}>I'll watch this later</StyledButton>
        <StyledButton className={classes.button} onClick={() => startTutorial()}>Let's go!</StyledButton>
      </DialogActions>
    </Dialog>
  )
}

export default Tutorial;