import React, { useContext } from "react";
import { Typography, CardContent, Card, CardHeader, CardActions, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import UserContext from "../../UserContext.js";
import StyledButton from "../common/StyledButton.js";
import { logAction } from "../../log.js";
import { createStripePortal } from "../../services/account_services.js";
import { useLocation } from "react-router-dom";
import { HelpOutline } from '@material-ui/icons';
import { DateTime } from "luxon";

const useStyles = makeStyles((theme) => ({
    lrLine: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
    },
    addButton: {
      display: "flex",
      justifyContent: "flex-end",
    },
    cardContainer: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      border: "none"
    },
    cardButton: {
      backgroundColor: theme.palette.common.white,
    },
    value: {
      fontWeight: "bold"
    },
    redText: {
      color: theme.palette.error.main
    },
    paymentTooltip: {
      cursor: "pointer"
    }
}));

const SubscriptionManagement = ({accountDetail}) => {
  const classes = useStyles();
  const location = useLocation();
  const { country } = useContext(UserContext);
  const locale = country === "US" ? "en-us" : "en-au";

  const openStripePortal = () => {
    logAction("Clicked Manage Subscription", country, location.pathname); //Mixpanel logging
    createStripePortal();
  }

  const displaySubExpiry = () => {
    if (Object.keys(accountDetail).length && !accountDetail.hasPaymentMethod) {
      return (
        <Tooltip title={
          <Typography variant="body2">
            To add a payment method, click <strong>Manage Subscription</strong> then <strong> Add payment method</strong>
          </Typography>
        }>
          <span className={`${classes.redText} ${classes.paymentTooltip}`}>
            No payment method
            <HelpOutline fontSize="small" />
          </span>
        </Tooltip>
      )
    } else if (accountDetail.subscriptionExpiry) {
      switch (accountDetail.subscriptionStatus) {
        case "incomplete_expired":
        case "canceled":
          return "-";
        default:
          let formattedDate = DateTime.fromISO(accountDetail.subscriptionExpiry).toLocaleString({day: 'numeric', month: 'long', year: 'numeric', locale: locale});
          if (accountDetail.cancelAtPeriodEnd) {
            formattedDate = <span className={classes.redText}>{formattedDate}</span>
          };
          return formattedDate;
      }
    }
  }

  const displayStatus = () => {
    if (accountDetail.subscriptionStatus) {
      switch (accountDetail.subscriptionStatus) {
        case "incomplete":
          return <span className={classes.redText}>Incomplete</span>;
        case "incomplete_expired":
          return <span className={classes.redText}>Expired</span>;
        case "trialing":
          return "Free trial";
        case "active":
          return "Active";
        case "past_due":
          return <span className={classes.redText}>Payment failed</span>;
        case "canceled":
          return <span className={classes.redText}>Cancelled</span>;
        case "unpaid":
          return <span className={classes.redText}>Unpaid</span>;
        default:
          break;
      }
    }
  };

  return (
    <Card variant="outlined" className={classes.cardContainer}>
      <CardHeader title="Subscription" />
      <CardContent>
          <div className={classes.lrLine}>
            <Typography>Status:</Typography>
            <Typography className={classes.value}>{displayStatus()}</Typography>
          </div>
          <div className={classes.lrLine}>
            <Typography>{accountDetail.cancelAtPeriodEnd ? "Cancelling:" : "Next Payment:"}</Typography>
            <Typography className={classes.value}>{displaySubExpiry()}</Typography>
          </div>
      </CardContent>
      <CardActions>
        <div className={classes.cardButton}>
          <StyledButton
            onClick={() => {
              openStripePortal();
            }}
          >
            Manage Subscription
          </StyledButton>
        </div>
      </CardActions>
    </Card>
  )
};

  export default SubscriptionManagement;