import React, { useContext } from 'react';
import { MenuItem, FormControl, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { logAction } from "../../log.js";
import { useLocation } from "react-router-dom";
import UserContext from "../../UserContext.js";

const useStyles = makeStyles(theme => ({
    studentPicker: {
        display: "flex",
        justifyContent: "center",
        paddingBottom: theme.spacing(2),
    },
}));

const StudentFilter = ({ students, selectedStudent, setSelectedStudent, includeAllOption = true }) => {

    const classes = useStyles();
    const location = useLocation();
    const { country } = useContext(UserContext);

    let selectableStudentList = includeAllOption ? [{ _id: 0, name: "All students" }, ...students] : students
    const handleChange = event => {
        logAction("Applied student filter", country, location.pathname, { value: event.target.value }); //Mixpanel logging
        setSelectedStudent(event.target.value);
    };

    const listItems = selectableStudentList.map((student) => (
        <MenuItem key={student._id} 
                  value={student._id}>
            {student.name}
        </MenuItem>));

    return (
        <div className={classes.studentPicker}>
            <FormControl variant="outlined" size="small">
                <Select
                    value={selectedStudent}
                    onChange={handleChange}
                >
                    {listItems}
                </Select>
            </FormControl>
        </div>
    )
}

export default StudentFilter;