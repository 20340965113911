import { getFilteredData } from './FilterData.js';

function getFilters(activities, categories, criteria) {
    let filters = [
        getFilteredData(activities, {
            student: criteria.student,
            completed: criteria.completed, 
            date: criteria.date
        }),
    ];

    filters = filters.concat(
        categories.map((category, i) => (
            getFilteredData(activities, {
                student: criteria.student,
                category: category,
                completed: criteria.completed, 
                date: criteria.date
            })
        ))
    )

    filters.push(
        getFilteredData(activities, {
            student: criteria.student,
            category: "none",
            completed: criteria.completed, 
            date: criteria.date
        })
    )

    return filters;
};

function getFilterListItems(activities, categories, setFilterIndex, criteria) {

    let filterLabels = [
        "All"
    ];

    filterLabels = filterLabels.concat(
        categories.map((category, i) => (
            category.name
        ))
    )

    filterLabels.push("Uncategorised")

    const filters = getFilters(activities, categories, criteria);

    const filterListItems = filters.map((filter, i) => (
        {
            key: i,
            label: filterLabels[i],
            count: [filter.length],
            onClick: () => { setFilterIndex(i) }
        }
    ))

    return filterListItems;
};

export { getFilters, getFilterListItems };