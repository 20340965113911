import { ReactComponent as read } from "../../assets/images/svg/icons8-read.svg";
import { ReactComponent as binoculars } from "../../assets/images/svg/icons8-binoculars.svg";
import { ReactComponent as adventures } from "../../assets/images/svg/icons8-adventures-50.svg";
import { ReactComponent as airplane } from "../../assets/images/svg/icons8-airplane-50.svg";
import { ReactComponent as apricot } from "../../assets/images/svg/icons8-apricot-50.svg";
import { ReactComponent as archersBow } from "../../assets/images/svg/icons8-archers-bow.svg";
import { ReactComponent as baseballBall } from "../../assets/images/svg/icons8-baseball-ball-50.svg";
import { ReactComponent as basketball } from "../../assets/images/svg/icons8-basketball-50.svg";
import { ReactComponent as beach } from "../../assets/images/svg/icons8-beach-50.svg";
import { ReactComponent as bear } from "../../assets/images/svg/icons8-bear-50.svg";
import { ReactComponent as beefBurger } from "../../assets/images/svg/icons8-beef-burger-50.svg";
import { ReactComponent as bigBen } from "../../assets/images/svg/icons8-big-ben-50.svg";
import { ReactComponent as birthdayCake } from "../../assets/images/svg/icons8-birthday-cake-50.svg";
import { ReactComponent as bonfire } from "../../assets/images/svg/icons8-bonfire-50.svg";
import { ReactComponent as bowling } from "../../assets/images/svg/icons8-bowling-50.svg";
import { ReactComponent as cactus } from "../../assets/images/svg/icons8-cactus-50.svg";
import { ReactComponent as calculator } from "../../assets/images/svg/icons8-calculator-50.svg";
import { ReactComponent as calendar } from "../../assets/images/svg/icons8-calendar-50.svg";
import { ReactComponent as candyCane } from "../../assets/images/svg/icons8-candy-cane-50.svg";
import { ReactComponent as cashRegister } from "../../assets/images/svg/icons8-cash-register-50.svg";
import { ReactComponent as castle } from "../../assets/images/svg/icons8-castle-50.svg";
import { ReactComponent as clock } from "../../assets/images/svg/icons8-clock-50.svg";
import { ReactComponent as communication } from "../../assets/images/svg/icons8-communication-50.svg";
import { ReactComponent as company } from "../../assets/images/svg/icons8-company-50.svg";
import { ReactComponent as courthouse } from "../../assets/images/svg/icons8-courthouse-50.svg";
import { ReactComponent as detective } from "../../assets/images/svg/icons8-detective-50.svg";
import { ReactComponent as dnaHelix } from "../../assets/images/svg/icons8-dna-helix-50.svg";
import { ReactComponent as documentary } from "../../assets/images/svg/icons8-documentary-50.svg";
import { ReactComponent as dog } from "../../assets/images/svg/icons8-dog-50.svg";
import { ReactComponent as earthPlanet } from "../../assets/images/svg/icons8-earth-planet-50.svg";
import { ReactComponent as electricity } from "../../assets/images/svg/icons8-electricity-50.svg";
import { ReactComponent as farm } from "../../assets/images/svg/icons8-farm-50.svg";
import { ReactComponent as flowerBouquet } from "../../assets/images/svg/icons8-flower-bouquet-50.svg";
import { ReactComponent as frankensteinsMonster } from "../../assets/images/svg/icons8-frankensteins-monster-50.svg";
import { ReactComponent as gameController } from "../../assets/images/svg/icons8-game-controller-50.svg";
import { ReactComponent as gardeningPlant } from "../../assets/images/svg/icons8-gardening-plant-50.svg";
import { ReactComponent as gift } from "../../assets/images/svg/icons8-gift-50.svg";
import { ReactComponent as goal } from "../../assets/images/svg/icons8-goal-50.svg";
import { ReactComponent as greatWall } from "../../assets/images/svg/icons8-great-wall-50.svg";
import { ReactComponent as guitar } from "../../assets/images/svg/icons8-guitar-50.svg";
import { ReactComponent as headWithBrain } from "../../assets/images/svg/icons8-head-with-brain-50.svg";
import { ReactComponent as himejiCastle } from "../../assets/images/svg/icons8-himeji-castle-50.svg";
import { ReactComponent as hive } from "../../assets/images/svg/icons8-hive-50.svg";
import { ReactComponent as holyBible } from "../../assets/images/svg/icons8-holy-bible-50.svg";
import { ReactComponent as idea } from "../../assets/images/svg/icons8-idea-50.svg";
import { ReactComponent as law } from "../../assets/images/svg/icons8-law-50.svg";
import { ReactComponent as literature } from "../../assets/images/svg/icons8-literature-50.svg";
import { ReactComponent as markerPen } from "../../assets/images/svg/icons8-marker-pen.svg";
import { ReactComponent as micro } from "../../assets/images/svg/icons8-micro-50.svg";
import { ReactComponent as microscope } from "../../assets/images/svg/icons8-microscope-50.svg";
import { ReactComponent as moneyBox } from "../../assets/images/svg/icons8-money-box-50.svg";
import { ReactComponent as monitor } from "../../assets/images/svg/icons8-monitor-50.svg";
import { ReactComponent as mountain } from "../../assets/images/svg/icons8-mountain-50.svg";
import { ReactComponent as music } from "../../assets/images/svg/icons8-music-50.svg";
import { ReactComponent as nailer } from "../../assets/images/svg/icons8-nailer-50.svg";
import { ReactComponent as owl } from "../../assets/images/svg/icons8-owl-50.svg";
import { ReactComponent as paintBrush } from "../../assets/images/svg/icons8-paint-brush-50.svg";
import { ReactComponent as paintPalette } from "../../assets/images/svg/icons8-paint-palette-50.svg";
import { ReactComponent as paperMoney } from "../../assets/images/svg/icons8-paper-money-50.svg";
import { ReactComponent as parthenon } from "../../assets/images/svg/icons8-parthenon-50.svg";
import { ReactComponent as partyBalloons } from "../../assets/images/svg/icons8-party-balloons-50.svg";
import { ReactComponent as pawn } from "../../assets/images/svg/icons8-pawn-50.svg";
import { ReactComponent as piano } from "../../assets/images/svg/icons8-piano-50.svg";
import { ReactComponent as pieChart } from "../../assets/images/svg/icons8-pie-chart-50.svg";
import { ReactComponent as pigeon } from "../../assets/images/svg/icons8-pigeon-50.svg";
import { ReactComponent as pingPong } from "../../assets/images/svg/icons8-ping-pong-50.svg";
import { ReactComponent as pottedPlant } from "../../assets/images/svg/icons8-potted-plant-50.svg";
import { ReactComponent as pyramids } from "../../assets/images/svg/icons8-pyramids-50.svg";
import { ReactComponent as rain } from "../../assets/images/svg/icons8-rain-50.svg";
import { ReactComponent as relievedFace } from "../../assets/images/svg/icons8-relieved-face-50.svg";
import { ReactComponent as restaurant } from "../../assets/images/svg/icons8-restaurant-50.svg";
import { ReactComponent as rocket } from "../../assets/images/svg/icons8-rocket-50.svg";
import { ReactComponent as rugby } from "../../assets/images/svg/icons8-rugby-50.svg";
import { ReactComponent as salmon } from "../../assets/images/svg/icons8-salmon-50.svg";
import { ReactComponent as screamingRooster } from "../../assets/images/svg/icons8-screaming-rooster-50.svg";
import { ReactComponent as shield } from "../../assets/images/svg/icons8-shield-50.svg";
import { ReactComponent as shop } from "../../assets/images/svg/icons8-shop-50.svg";
import { ReactComponent as smallTelescope } from "../../assets/images/svg/icons8-small-telescope-50.svg";
import { ReactComponent as soccerBall } from "../../assets/images/svg/icons8-soccer-ball-50.svg";
import { ReactComponent as spaceShuttle } from "../../assets/images/svg/icons8-space-shuttle-50.svg";
import { ReactComponent as stationery } from "../../assets/images/svg/icons8-stationery-50.svg";
import { ReactComponent as studentMale } from "../../assets/images/svg/icons8-student-male-50.svg";
import { ReactComponent as sunset } from "../../assets/images/svg/icons8-sunset-50.svg";
import { ReactComponent as survey } from "../../assets/images/svg/icons8-survey-50.svg";
import { ReactComponent as swimmingPool } from "../../assets/images/svg/icons8-swimming-pool-50.svg";
import { ReactComponent as sydneyOperaHouse } from "../../assets/images/svg/icons8-sydney-opera-house-50.svg";
import { ReactComponent as tajMahal } from "../../assets/images/svg/icons8-taj-mahal-50.svg";
import { ReactComponent as tennisBall } from "../../assets/images/svg/icons8-tennis-ball-50.svg";
import { ReactComponent as testTube } from "../../assets/images/svg/icons8-test-tube-50.svg";
import { ReactComponent as thermometer } from "../../assets/images/svg/icons8-thermometer-50.svg";
import { ReactComponent as thriller } from "../../assets/images/svg/icons8-thriller-50.svg";
import { ReactComponent as tomato } from "../../assets/images/svg/icons8-tomato-50.svg";
import { ReactComponent as tractor } from "../../assets/images/svg/icons8-tractor-50.svg";
import { ReactComponent as tree } from "../../assets/images/svg/icons8-tree-50.svg";
import { ReactComponent as trigonometry } from "../../assets/images/svg/icons8-trigonometry-50.svg";
import { ReactComponent as trust } from "../../assets/images/svg/icons8-trust-50.svg";
import { ReactComponent as usCapitol } from "../../assets/images/svg/icons8-us-capitol-50.svg";
import { ReactComponent as vampire } from "../../assets/images/svg/icons8-vampire-50.svg";
import { ReactComponent as volcano } from "../../assets/images/svg/icons8-volcano-50.svg";
import { ReactComponent as worldMap } from "../../assets/images/svg/icons8-world-map-50.svg";

const getProjectIcons = () => {
    let icons = [];
    icons["adventures"] = adventures;
    icons["airplane"] = airplane;
    icons["apricot"] = apricot;
    icons["archersBow"] = archersBow;
    icons["baseballBall"] = baseballBall;
    icons["basketball"] = basketball;
    icons["beach"] = beach
    icons["bear"] = bear;
    icons["beefBurger"] = beefBurger;
    icons["bigBen"] = bigBen;
    icons["binoculars"] = binoculars;
    icons["birthdayCake"] = birthdayCake;
    icons["bonfire"] = bonfire;
    icons["bowling"] = bowling;
    icons["cactus"] = cactus;
    icons["calculator"] = calculator;
    icons["calendar"] = calendar;
    icons["candyCane"] = candyCane;
    icons["cashRegister"] = cashRegister;
    icons["castle"] = castle;
    icons["clock"] = clock;
    icons["communication"] = communication;
    icons["company"] = company;
    icons["courthouse"] = courthouse;
    icons["detective"] = detective;
    icons["dnaHelix"] = dnaHelix;
    icons["documentary"] = documentary;
    icons["dog"] = dog;
    icons["earthPlanet"] = earthPlanet;
    icons["electricity"] = electricity;
    icons["farm"] = farm;
    icons["flowerBouquet"] = flowerBouquet;
    icons["frankensteinsMonster"] = frankensteinsMonster;
    icons["gameController"] = gameController;
    icons["gardeningPlant"] = gardeningPlant;
    icons["gift"] = gift;
    icons["goal"] = goal;
    icons["greatWall"] = greatWall;
    icons["guitar"] = guitar;
    icons["headWithBrain"] = headWithBrain;
    icons["himejiCastle"] = himejiCastle;
    icons["hive"] = hive;
    icons["holyBible"] = holyBible;
    icons["idea"] = idea;
    icons["law"] = law;
    icons["literature"] = literature;
    icons["markerPen"] = markerPen;
    icons["micro"] = micro;
    icons["microscope"] = microscope;
    icons["moneyBox"] = moneyBox;
    icons["monitor"] = monitor;
    icons["mountain"] = mountain;
    icons["music"] = music;
    icons["nailer"] = nailer;
    icons["owl"] = owl;
    icons["paintBrush"] = paintBrush;
    icons["paintPalette"] = paintPalette;
    icons["paperMoney"] = paperMoney;
    icons["parthenon"] = parthenon;
    icons["partyBalloons"] = partyBalloons;
    icons["pawn"] = pawn;
    icons["piano"] = piano;
    icons["pieChart"] = pieChart;
    icons["pigeon"] = pigeon;
    icons["pingPong"] = pingPong;
    icons["pottedPlant"] = pottedPlant;
    icons["pyramids"] = pyramids;
    icons["rain"] = rain;
    icons["read"] = read;
    icons["relievedFace"] = relievedFace;
    icons["restaurant"] = restaurant;
    icons["rocket"] = rocket;
    icons["rugby"] = rugby;
    icons["salmon"] = salmon;
    icons["screamingRooster"] = screamingRooster;
    icons["shield"] = shield;
    icons["shop"] = shop;
    icons["smallTelescope"] = smallTelescope;
    icons["soccerBall"] = soccerBall;
    icons["spaceShuttle"] = spaceShuttle;
    icons["stationery"] = stationery;
    icons["studentMale"] = studentMale;
    icons["sunset"] = sunset;
    icons["survey"] = survey;
    icons["swimmingPool"] = swimmingPool;
    icons["sydneyOperaHouse"] = sydneyOperaHouse;
    icons["tajMahal"] = tajMahal;
    icons["tennisBall"] = tennisBall;
    icons["testTube"] = testTube;
    icons["thermometer"] = thermometer;
    icons["thriller"] = thriller;
    icons["tomato"] = tomato;
    icons["tractor"] = tractor;
    icons["tree"] = tree;
    icons["trigonometry"] = trigonometry;
    icons["trust"] = trust;
    icons["usCapitol"] = usCapitol;
    icons["vampire"] = vampire;
    icons["volcano"] = volcano;
    icons["worldMap"] = worldMap;
    
    return icons;
}

export { getProjectIcons };