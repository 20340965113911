import React from "react";
import StudentsList from "../../components/students/StudentsList.js";
import NewStudent from "../../components/students/NewStudentButton.js";
import PageSimple from "../../components/layout/PageSimple.js";

const Students = ({ students, setStudents, navOpen, drawerWidth, bannerClearance }) => {

  return (
    <PageSimple navOpen={navOpen} drawerWidth={drawerWidth} bannerClearance={bannerClearance}>
      <StudentsList students={students} setStudents={setStudents} />
      <NewStudent students={students} setStudents={setStudents} />
    </PageSimple>
  );
};

export default Students;
