import React, { useState, useEffect, useRef, useContext } from 'react';
import { useTheme, makeStyles } from "@material-ui/core";
import { useMediaQuery, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Menu, MenuItem, Fab, Input, Tooltip } from '@material-ui/core';
import NewActivity from '../../containers/activities/NewActivity.js';
import Spinner from './Spinner.js';
import { logAction } from "../../log.js";
import UserContext from "../../UserContext.js";
import { useLocation } from "react-router-dom";
import { useReactToPrint } from 'react-to-print';
import { ArrowBack } from "@material-ui/icons";
import { ArrowForward } from '@material-ui/icons';
import { MoreVert } from "@material-ui/icons";
import { ZoomIn } from '@material-ui/icons';
import { ZoomOut } from '@material-ui/icons';
import { Add } from '@material-ui/icons';
import { Document, Page, pdfjs } from "react-pdf";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const useStyles = makeStyles(theme => ({
    dialogTitle: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        alignItems: "center",
    },
    titleText: {
        flexGrow: 1,
        textAlign: "center"
    },
    pdfContainer: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
    },
    pdfNav: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        alignItems: "center",
    },
    zoomInButton: {
        display: 'flex',
        justifyContent: 'center',
        position: 'fixed',
        zIndex: 1,
        bottom: theme.spacing(10),
        right: theme.spacing(3),
        backgroundColor: theme.palette.grey[600],
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.grey[900],
        },
        [theme.breakpoints.down('xs')]: {
            bottom: theme.spacing(9),
        },
    },
    zoomOutButton: {
        display: 'flex',
        justifyContent: 'center',
        position: 'fixed',
        zIndex: 1,
        bottom: theme.spacing(3),
        right: theme.spacing(3),
        backgroundColor: theme.palette.grey[600],
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.grey[900],
        },
    },
    pdfPrint: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    hidden: {
        display: "none"
    },
    pageNumber: {
        width: theme.spacing(5),
    }
}));

const PdfViewer = ({ showPdfViewer, setShowPdfViewer, pdfProps, allowAddActivity = false, optionalContext = {}, setNotificationDetails = null }) => {
    const classes = useStyles();
    const theme = useTheme();
    const location = useLocation();
    const componentRef = useRef();
    const [anchorEl, setAnchorEl] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageNumberDisplay, setPageNumberDisplay] = useState(1);
    const [pdfHeight, setPdfHeight] = useState(null);
    const [pdfWidth, setPdfWidth] = useState(null);
    const [pdfPrintWidth, setPdfPrintWidth] = useState(null);
    const [pageStyle, setPageStyle] = useState(null);
    const [scale, setScale] = useState(1);
    const [rendering, setRendering] = useState(true);
    const screenIsXs = useMediaQuery(theme.breakpoints.down("xs"));
    const [showNewActivityModal, setShowActivityModal] = useState(false);
    const { country, userPermissions } = useContext(UserContext);

    useEffect(() => {
        setRendering(true)
    }, [showPdfViewer, pageNumber, scale]);

    useEffect(() => {
        pdfProps.pageNo &&
        setPageNumber(parseInt(pdfProps.pageNo))
    }, [pdfProps]);

    useEffect(() => {
        if(window.innerHeight > window.innerWidth){ //Portrait
            setPdfWidth(window.innerWidth);
        } else { //Landscape
            setPdfHeight(window.innerHeight - 150); //150 is to cater for top and bottom menus
        }
    }, [showPdfViewer]);

    useEffect(() => {
        setPageNumberDisplay(pageNumber);
    }, [pageNumber]);

    const onDocumentLoadSuccess = (pdf) => {
        setNumPages(pdf.numPages);
        if (pageNumber > pdf.numPages) { //Default to page 1 if provided pageNumber is out of range
            setPageNumber(1);
        }
    }

    const pdfMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const pdfMenuClose = () => {
        setAnchorEl(null);
    };

    const handlePrint = useReactToPrint({
        bodyClass: classes.pdfPrint,
        pageStyle: pageStyle,
        content: () => componentRef.current,
    });

    const hideModal = () => {
        setShowPdfViewer(false);
        setNumPages(null);
        setPageNumber(1);
        setScale(1);
    }

    const updatePageNumber = () => {
        if (pageNumberDisplay > 0 && pageNumberDisplay <= numPages) {
            setPageNumber(pageNumberDisplay);
        }
    }

    const onChangePageNumberDisplay = (event) => {
        const value = event.target.value;
        if (value) {
            if (!isNaN(value)) {
                setPageNumberDisplay(parseInt(value));
            }
        } else {
            setPageNumberDisplay("")
        }
    }

    const onKeyDownPageNumberDisplay = (event) => {
        if (event.keyCode === 13) { //i.e. Enter key
            updatePageNumber();
        }
    }

    const openLinkInNewTab = (e) => {
        e.preventDefault();
        if (e.target.tagName.toLowerCase() === 'a') {
            window.open(e.target.href);
        }
    }

    return (
        <Dialog open={showPdfViewer} fullScreen>
            <DialogTitle>
                <div className={classes.dialogTitle}>
                    <IconButton onClick={() => {hideModal()}}>
                        <ArrowBack />
                    </IconButton>
                <div className={classes.titleText}>PDF Viewer</div>
                {userPermissions.addActivity && allowAddActivity && !screenIsXs &&
                <Tooltip title="Add Activity">
                    <IconButton onClick={() => {setShowActivityModal(true)}}>
                        <Add />
                    </IconButton>
                </Tooltip>
                }  
                <IconButton onClick={pdfMenuOpen}>
                    <MoreVert />
                </IconButton>
                <Menu id="pdf-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClick={pdfMenuClose} onClose={pdfMenuClose}>
                    <MenuItem
                        onClick={() => {
                            window.open(pdfProps.url, "_blank");
                        }}
                    >
                        Open in browser
                    </MenuItem>
                    <MenuItem
                        onClick={handlePrint}
                    >
                        Print this page
                    </MenuItem>
                    {allowAddActivity && screenIsXs &&
                    <MenuItem
                        onClick={() => {setShowActivityModal(true)}}
                    >
                        Add Activity
                    </MenuItem>
                    }
                </Menu>
                </div>
            </DialogTitle>
            <DialogContent>
            {pdfProps.hasOwnProperty('file') &&
                <div className={classes.pdfContainer} onClick={openLinkInNewTab}>
                    <Document
                        file={pdfProps.file}
                        onLoadSuccess={onDocumentLoadSuccess}
                        onLoadError={() => {
                            setRendering(false)
                            logAction("PDF document loading failed", country, location.pathname); //Mixpanel logging
                        }}
                    >
                        <div style={{ //limit viewport so scroll bars appear when zoomed in
                            height: pdfHeight,
                            width: pdfWidth,
                        }}>
                            <Page 
                                height={pdfHeight}
                                width={pdfWidth}
                                pageNumber={pageNumber}
                                scale={scale}
                                renderTextLayer={false}
                                onRenderSuccess={() => {
                                    setRendering(false)
                                    logAction("PDF page rendered successfully", country, location.pathname); //Mixpanel logging
                                }}
                                onLoadError={() => {
                                    setRendering(false)
                                    logAction("PDF page loading failed", country, location.pathname); //Mixpanel logging
                                }}
                                onRenderError={() => {
                                    setRendering(false)
                                    logAction("PDF page rendering failed", country, location.pathname); //Mixpanel logging
                                }}
                            />
                        </div>
                        <div className={classes.hidden}> {/* for printing only */}
                            <Page 
                                width={pdfPrintWidth}
                                pageNumber={pageNumber}
                                scale={1}
                                ref={componentRef}
                                renderTextLayer={false}
                                onLoadSuccess={(page) => {
                                    if (page.height > page.width) {
                                        setPageStyle(`@page { size: portrait; margin: 0 }`);
                                        setPdfPrintWidth(793) //A4 width in pixels
                                    } else {
                                        setPageStyle(`@page { size: landscape; margin: 0 }`);
                                        setPdfPrintWidth(1054) //Letter height in pixels
                                    }
                                }}
                            />
                        </div>
                    </Document>
                </div> 
            }
            </DialogContent>
            <DialogActions>
                <div className={classes.pdfNav}>
                    <IconButton
                        disabled={pageNumber <= 1}
                        onClick={() => {
                            setPageNumber(pageNumber - 1);
                        }}
                    >
                        <ArrowBack />
                    </IconButton>
                    <Input 
                        value={pageNumberDisplay} 
                        onChange={onChangePageNumberDisplay} 
                        onBlur={updatePageNumber}
                        onKeyDown={onKeyDownPageNumberDisplay}
                        className={classes.pageNumber}
                        inputProps={{style: { textAlign: "center"}}}
                    /> of {numPages}
                    <IconButton
                        disabled={pageNumber >= numPages}
                        onClick={() => {
                            setPageNumber(pageNumber + 1);
                        }}
                    >
                        <ArrowForward />
                    </IconButton>
                </div>
            </DialogActions>
            <Fab 
                size={screenIsXs ? "small" : "medium"}
                className={classes.zoomInButton}
                onClick={() => {
                    setScale(scale + 0.2)
                }}>
                <ZoomIn />
            </Fab>
            <Fab 
                size={screenIsXs ? "small" : "medium"}
                className={classes.zoomOutButton}
                onClick={() => {
                    setScale(scale - 0.2)
                }}>
                <ZoomOut />
            </Fab>
            <Spinner open={rendering} />
            {allowAddActivity &&
            <NewActivity
                setActivities={optionalContext.setActivities}
                activities={optionalContext.activities}
                isOpen={showNewActivityModal}
                setShowActivityModal={setShowActivityModal}
                students={optionalContext.students}
                categories={optionalContext.categories} 
                projects={optionalContext.projects}
                projectId={optionalContext.projectId}
                pdfDetails={{
                    userFileName: pdfProps.name,
                    s3FileName: pdfProps.s3FileName,
                    fileType: pdfProps.fileType,
                    pageNumber: pageNumber,
                    linkText: pdfProps.name + ' (Page ' + pageNumber + ')' ,
                    type: "project"
                }}
                projectStudents={optionalContext.projectStudents}
                projectCategories={optionalContext.projectCategories}
                setNotificationDetails={setNotificationDetails}
            />
            }
        </Dialog>
    )
};

export default PdfViewer;