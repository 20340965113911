import { useMediaQuery } from "@material-ui/core";
import { useTheme, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
      transitionProperty: "margin-left",
      transitionDuration: theme.transitions.duration.short,
    }
}));

const Page = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const screenIsXs = useMediaQuery(theme.breakpoints.down("xs"));
    
    let leftMargin = 0;
    if (props.navOpen && !screenIsXs) {
      leftMargin = props.drawerWidth;
    }

    return (
      <div className={classes.root} style={{ marginLeft: leftMargin }}>
          {props.children}
      </div>
    )
};

export default Page;