import { makeStyles } from "@material-ui/core";
import PageBase from "./PageBase.js";

const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      maxWidth: "500px",
      paddingLeft: "5px",
      paddingRight: "5px",
    }
}));

const PageSimple = (props) => {
    const classes = useStyles();

    return (
      <PageBase navOpen={props.navOpen} drawerWidth={props.drawerWidth} bannerClearance={props.bannerClearance}>
        <div style={{height: props.bannerClearance}} />
        <div className={classes.root}>
          {props.children}
        </div>
      </PageBase>
    )
};

export default PageSimple;