import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core";
import { InputLabel, Dialog, DialogTitle, DialogContent, Typography, DialogActions } from "@material-ui/core";
import StyledButton from "../common/StyledButton.js";
import Comment from "./Comment.js";
import AddComment from "./AddComment.js";
import UserContext from "../../UserContext.js";
import { logAction } from "../../log.js";

const useStyles = makeStyles((theme) => ({
    label: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(2),
    }
  }));

const Comments = ({comments, setComments, commentText, setCommentText, submitFunction, students, location}) => {
  const classes = useStyles();
  const { country } = useContext(UserContext);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(0);

  const confirmDelete = () => {
    logAction("Deleted activity comment", country, location.pathname); //Mixpanel logging
    let commentsTemp = [...comments];
    commentsTemp.splice(deleteIndex, 1);
    setComments(commentsTemp);
    setDeleteIndex(0);
    setShowDeleteConfirm(false);
  }

  const cancelDelete = () => {
    setDeleteIndex(0);
    setShowDeleteConfirm(false);
  };

  const handleDelete = (i) => {
    setDeleteIndex(i);
    setShowDeleteConfirm(true);
  }

  const deleteConfirmModal = () => {
    return (
      <Dialog open={showDeleteConfirm}>
        <DialogTitle>Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this comment?</Typography>
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={() => cancelDelete()}>Cancel</StyledButton>
          <StyledButton onClick={() => confirmDelete()}>OK</StyledButton>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <div>
      <InputLabel className={classes.label}>Comments</InputLabel>
      {
        comments.length 
        ? comments.map((comment, i) => {
          return <Comment key={i} comment={comment} students={students} index={i} handleDelete={() => handleDelete(i)} />
        })
        : null
      }
      <AddComment comments={comments} setComments={setComments} commentText={commentText} setCommentText={setCommentText} submitFunction={submitFunction} location={location} />
      {deleteConfirmModal()}
    </div>
  );
};

export default Comments;