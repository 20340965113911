import React from 'react';
import { LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: (props) => props.secondaryColor,
    },
    barColorPrimary: {
        backgroundColor: (props) => props.color,
    },
    container: {
        width: "100%",
        [theme.breakpoints.down('xs')]: {
            width: "100%",
        }
    },
    progressBar: {
        height: "10px",
        borderRadius: "5px",
        [theme.breakpoints.down('xs')]: {
            height: "6px",
            borderRadius: "3px",
        },
    },
  }));

const CustomProgressBar = (props) => {
    const { color, secondaryColor, value, className, ...other } = props;
    const classes = useStyles(props);
    return (
        <div className={classes.container}>
            <LinearProgress 
                className={classes.progressBar}
                classes={{
                    root: classes.root,
                    barColorPrimary: classes.barColorPrimary
                }}
                value={value}
                variant="determinate"
                {...other} 
            />
        </div>
    )
}

export default CustomProgressBar;